 
import React  from 'react';

const colorMap  = {
    "Ready": "YellowGreen",
    "Active": "Green",
    "Off": "Gray",
    "Error": "Red",
    "Starting": "GreenYellow",
    "Starting...": "GreenYellow",
    "Stopping...": "Red",
    "Stopping": "Red",
    "Paused": "Orange",
    "Unavailable": "Red",
}
function StatsPanel (props) {
	
	return	(
        <div id='stats-panel'>
			<h4 className='status-autoPilot'>    Status: <b style={{color:colorMap[props.autoPilotState], display:"inline"}}>{props.autoPilotState}</b></h4>
            <p style={{color:"Orange", display:"inline"}}>   {props.autoPilotState === "Ready"? "  *Press 'Resume' to start dispatching\n":" \n"}</p>
		</div>)
	
}

export default StatsPanel;