import React, {Component, Fragment} from 'react';
// import react bootstrap;
import Modal from 'react-bootstrap/Modal'
import {Button} from 'react-bootstrap';
import './info-panel-styles.css';
import Form from 'react-bootstrap/Form'

import './info-panel-styles.css';

const text = "This is a console for the Auto-Pilot application.\n";

const MyVerticallyCenteredModal = (props) =>{
    return (
      <Modal
        id="consoleModal"
        size="lg" 
        show={props.show}
        aria-labelledby="example-custom-modal-styling-title" 
        centered
      >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Auto-Pilot -- Console
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="display-linebreak" >
        <div className="display-linebreak" style={{fontSize: "12px"}}> 
            {(props.trace)} 
        </div>

      </Modal.Body>
      <Modal.Footer>
      <Button variant="primary" onClick={()=> {
            props.onHide(false)       
        }}>
      Close
      </Button>
      </Modal.Footer>
    </Modal>
    );
};

export default MyVerticallyCenteredModal;