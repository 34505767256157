import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Component } from "react";
import AreaSelectionComponent from './AreaSelectionComponent';
import { getConfig,postConfig } from './Caller';
import { Spinner } from 'react-bootstrap';
import { DisplayModeContext } from './DisplayModeContext';
const EMPTY_CONFIG={index: 0, start_time:"",end_time:"",sectors:[],bonus:0};
export default class SectorConfigModal extends Component {
    
    constructor(props){
        super(props);
        this.state = {context: [],isLoading: true}
        this.addConfig = this.addConfig.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSave = this.onSave.bind(this);
    }
    async componentDidMount(){

      const rep = await getConfig();
      console.log("reppp");
      console.log(rep)
      this.setState({context: rep.content, isLoading: false});

    }
    async onSave(event){
      event.stopPropagation();
      event.preventDefault();
      const rep = await postConfig(this.state.context);

      console.log("response server")
      console.log(rep)
      this.props.handleClose()
    }
    addConfig(){
        const copyContext = [...this.state.context];
        const newConfig = {...EMPTY_CONFIG};
        newConfig.index = copyContext.findLast((element) => element.index >= 0).index + 1 ;
        copyContext.push(newConfig);
        this.setState({context: copyContext});
    }
    onChange(event, index){
      const copyContext = [...this.state.context];
      switch(event.target.name){
        case 'startTime':
          copyContext[index].start_time = event.target.value;
          break;
        case 'endTime':
          copyContext[index].end_time = event.target.value;
          break;
        case 'bonus':
          if(event.target.value === '') {
            copyContext[index].bonus = '';
            break;
          }
          const value = parseFloat(event.target.value)
          if(!isNaN(value)) copyContext[index].bonus = value;
            break;
          default:
            console.log("pog");
      }
      this.setState({context : copyContext});
    }
    handleAreaChange(updates,index){
      const copyContext = [...this.state.context];
      copyContext[index].sectors = updates ;
      this.setState({context : copyContext});
    }
    deleteConfig(index){
      const copyContext = [...this.state.context];
      copyContext.splice(index, 1); 
      this.setState({context : copyContext});
    }
    static contextType = DisplayModeContext;

    render() {
      return (
        <Modal id={this.context.displayMode} backdrop="false" size="xl" show={this.props.show}  aria-labelledby="contained-modal-title-vcenter" centered>
         <Form  onSubmit={(event) => {this.onSave(event)}}>
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
               Sector Configs Menu
            </Modal.Title>
          </Modal.Header>
          
          {this.state.isLoading?
            <div className='flex-center spinner'>
              <Spinner size="xl"  animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          :
            <Modal.Body>
            {this.state.context.map((config,index)=>{
                  return(
                    
                  <Row className="config-row mb-3">
                  <Col className='flex-center' xs={1}>
                      { (config.index + 1 )}
                  </Col>
                  <Col xs={2}>
                      <Form.Control required name='startTime' value={this.state.context[index].start_time} onChange={(event)=>{this.onChange(event,index)}} type ="time"aria-label="Floating label select example"></Form.Control> 
                  </Col>
                  <Col xs={2}>
                      <Form.Control required type="time" name='endTime' value={this.state.context[index].end_time} onChange={(event)=>{this.onChange(event,index)}} aria-label="Floating label select example"></Form.Control> 
                  </Col>
                  <Col>
                      <AreaSelectionComponent update={(updates) =>{this.handleAreaChange(updates,index)}} areas={this.state.context[index].sectors}></AreaSelectionComponent> 
                  </Col>
                  <Col className='flex-center' xs={2}>
                      <Form.Control required name='bonus' type="number" step="0.1" value={this.state.context[index].bonus} onChange={(event)=>{this.onChange(event,index)}}></Form.Control>
                  </Col>
                  <Col className='flex-center' xs={1}>
                  <button id ="config-add-button" onClick={() => {this.deleteConfig(index)}}  class=" btn btn-danger">X</button>
                  </Col>
                  </Row>
  )
            })}
              <div className='flex-center'>
                  <button id ="config-add-button" onClick={() => {this.addConfig()}}  class="config-add-button btn btn-primary">Add</button>
              </div>
              </Modal.Body>}
        {this.state.isLoading? <div></div>:<Modal.Footer>
          <button onClick={() => {this.props.handleClose()}}  class="btn btn-danger">Cancel</button>
          <button class="btn btn-primary" type="submit">Save</button>
        </Modal.Footer>}
        </Form>
      </Modal>
      );
    }
  }