export default function getRouteConfigs(networkId, routeIds)
{
    return {
        "version": "v1",
        "config": {
          "visState": {
            "filters": [
              {
                "dataId": [
                  "rbiikmwso"
                ],
                "id": "ldgrzfnx",
                "name": [
                  "battery to swap"
                ],
                "type": "range",
                "value": [
                  0.41,
                  6
                ],
                "enlarged": false,
                "plotType": "histogram",
                "animationWindow": "free",
                "yAxis": null,
                "speed": 1
              }
            ],
            "layers": [
              {
                "id": "rb551eh",
                "type": "point",
                "config": {
                  "dataId": "rbiikmwso",
                  "label": "Point",
                  "color": [
                    255,
                    203,
                    153
                  ],
                  "highlightColor": [
                    252,
                    242,
                    26,
                    255
                  ],
                  "columns": {
                    "lat": "lat",
                    "lng": "lon",
                    "altitude": null
                  },
                  "isVisible": true,
                  "visConfig": {
                    "radius": 10,
                    "fixedRadius": false,
                    "opacity": 0.8,
                    "outline": false,
                    "thickness": 2,
                    "strokeColor": null,
                    "colorRange": {
                      "name": "Global Warming",
                      "type": "sequential",
                      "category": "Uber",
                      "colors": [
                        "#5A1846",
                        "#900C3F",
                        "#C70039",
                        "#E3611C",
                        "#F1920E",
                        "#FFC300"
                      ]
                    },
                    "strokeColorRange": {
                      "name": "Global Warming",
                      "type": "sequential",
                      "category": "Uber",
                      "colors": [
                        "#5A1846",
                        "#900C3F",
                        "#C70039",
                        "#E3611C",
                        "#F1920E",
                        "#FFC300"
                      ]
                    },
                    "radiusRange": [
                      "15",
                      57
                    ],
                    "filled": true
                  },
                  "hidden": false,
                  "textLabel": [
                    {
                      "field": {
                        "name": "battery to swap",
                        "type": "integer"
                      },
                      "color": [
                        66,
                        101,
                        204
                      ],
                      "size": 18,
                      "offset": [
                        0,
                        0
                      ],
                      "anchor": "start",
                      "alignment": "center"
                    }
                  ]
                },
                "visualChannels": {
                  "colorField": {
                    "name": "battery to swap",
                    "type": "integer"
                  },
                  "colorScale": "quantize",
                  "strokeColorField": null,
                  "strokeColorScale": "quantile",
                  "sizeField": {
                    "name": "battery to swap",
                    "type": "integer"
                  },
                  "sizeScale": "sqrt"
                }
              },
              {
                "id": "37j9xcr",
                "type": "geojson",
                "config": {
                  "dataId": "jpo3nqrxe",
                  "label": "HEC",
                  "color": [
                    136,
                    87,
                    44
                  ],
                  "highlightColor": [
                    252,
                    242,
                    26,
                    255
                  ],
                  "columns": {
                    "geojson": "_geojson"
                  },
                  "isVisible": true,
                  "visConfig": {
                    "opacity": 0.8,
                    "strokeOpacity": 0.5,
                    "thickness": 1.5,
                    "strokeColor": [
                      206,
                      64,
                      170
                    ],
                    "colorRange": {
                      "name": "Global Warming",
                      "type": "sequential",
                      "category": "Uber",
                      "colors": [
                        "#5A1846",
                        "#900C3F",
                        "#C70039",
                        "#E3611C",
                        "#F1920E",
                        "#FFC300"
                      ]
                    },
                    "strokeColorRange": {
                      "name": "Global Warming",
                      "type": "sequential",
                      "category": "Uber",
                      "colors": [
                        "#5A1846",
                        "#900C3F",
                        "#C70039",
                        "#E3611C",
                        "#F1920E",
                        "#FFC300"
                      ]
                    },
                    "radius": 10,
                    "sizeRange": [
                      0,
                      10
                    ],
                    "radiusRange": [
                      0,
                      50
                    ],
                    "heightRange": [
                      0,
                      500
                    ],
                    "elevationScale": 5,
                    "enableElevationZoomFactor": true,
                    "stroked": true,
                    "filled": false,
                    "enable3d": false,
                    "wireframe": false
                  },
                  "hidden": false,
                  "textLabel": [
                    {
                      "field": null,
                      "color": [
                        255,
                        255,
                        255
                      ],
                      "size": 18,
                      "offset": [
                        0,
                        0
                      ],
                      "anchor": "start",
                      "alignment": "center"
                    }
                  ]
                },
                "visualChannels": {
                  "colorField": null,
                  "colorScale": "quantile",
                  "strokeColorField": null,
                  "strokeColorScale": "quantile",
                  "sizeField": null,
                  "sizeScale": "linear",
                  "heightField": null,
                  "heightScale": "linear",
                  "radiusField": null,
                  "radiusScale": "linear"
                }
              },
              {
                "id": "nwjbwp",
                "type": "geojson",
                "config": {
                  "dataId": "6eimmjib",
                  "label": "Auto E06",
                  "color": [
                    137,
                    218,
                    193
                  ],
                  "highlightColor": [
                    252,
                    242,
                    26,
                    255
                  ],
                  "columns": {
                    "geojson": "_geojson"
                  },
                  "isVisible": true,
                  "visConfig": {
                    "opacity": 0.8,
                    "strokeOpacity": 0.8,
                    "thickness": 1.5,
                    "strokeColor": [
                      234,
                      68,
                      68
                    ],
                    "colorRange": {
                      "name": "Global Warming",
                      "type": "sequential",
                      "category": "Uber",
                      "colors": [
                        "#5A1846",
                        "#900C3F",
                        "#C70039",
                        "#E3611C",
                        "#F1920E",
                        "#FFC300"
                      ]
                    },
                    "strokeColorRange": {
                      "name": "Global Warming",
                      "type": "sequential",
                      "category": "Uber",
                      "colors": [
                        "#5A1846",
                        "#900C3F",
                        "#C70039",
                        "#E3611C",
                        "#F1920E",
                        "#FFC300"
                      ]
                    },
                    "radius": 10,
                    "sizeRange": [
                      0,
                      10
                    ],
                    "radiusRange": [
                      0,
                      50
                    ],
                    "heightRange": [
                      0,
                      500
                    ],
                    "elevationScale": 5,
                    "enableElevationZoomFactor": true,
                    "stroked": true,
                    "filled": false,
                    "enable3d": false,
                    "wireframe": false
                  },
                  "hidden": false,
                  "textLabel": [
                    {
                      "field": null,
                      "color": [
                        255,
                        255,
                        255
                      ],
                      "size": 18,
                      "offset": [
                        0,
                        0
                      ],
                      "anchor": "start",
                      "alignment": "center"
                    }
                  ]
                },
                "visualChannels": {
                  "colorField": null,
                  "colorScale": "quantile",
                  "strokeColorField": null,
                  "strokeColorScale": "quantile",
                  "sizeField": null,
                  "sizeScale": "linear",
                  "heightField": null,
                  "heightScale": "linear",
                  "radiusField": null,
                  "radiusScale": "linear"
                }
              },
              {
                "id": "g66bn9",
                "type": "geojson",
                "config": {
                  "dataId": "zba9w1rnn",
                  "label": "Auto EV08",
                  "color": [
                    179,
                    173,
                    158
                  ],
                  "highlightColor": [
                    252,
                    242,
                    26,
                    255
                  ],
                  "columns": {
                    "geojson": "_geojson"
                  },
                  "isVisible": true,
                  "visConfig": {
                    "opacity": 0.8,
                    "strokeOpacity": 0.5,
                    "thickness": 1.5,
                    "strokeColor": [
                      105,
                      79,
                      214
                    ],
                    "colorRange": {
                      "name": "Global Warming",
                      "type": "sequential",
                      "category": "Uber",
                      "colors": [
                        "#5A1846",
                        "#900C3F",
                        "#C70039",
                        "#E3611C",
                        "#F1920E",
                        "#FFC300"
                      ]
                    },
                    "strokeColorRange": {
                      "name": "Global Warming",
                      "type": "sequential",
                      "category": "Uber",
                      "colors": [
                        "#5A1846",
                        "#900C3F",
                        "#C70039",
                        "#E3611C",
                        "#F1920E",
                        "#FFC300"
                      ]
                    },
                    "radius": 10,
                    "sizeRange": [
                      0,
                      10
                    ],
                    "radiusRange": [
                      0,
                      50
                    ],
                    "heightRange": [
                      0,
                      500
                    ],
                    "elevationScale": 5,
                    "enableElevationZoomFactor": true,
                    "stroked": true,
                    "filled": false,
                    "enable3d": false,
                    "wireframe": false
                  },
                  "hidden": false,
                  "textLabel": [
                    {
                      "field": null,
                      "color": [
                        255,
                        255,
                        255
                      ],
                      "size": 18,
                      "offset": [
                        0,
                        0
                      ],
                      "anchor": "start",
                      "alignment": "center"
                    }
                  ]
                },
                "visualChannels": {
                  "colorField": null,
                  "colorScale": "quantile",
                  "strokeColorField": null,
                  "strokeColorScale": "quantile",
                  "sizeField": null,
                  "sizeScale": "linear",
                  "heightField": null,
                  "heightScale": "linear",
                  "radiusField": null,
                  "radiusScale": "linear"
                }
              },
              {
                "id": "2gge55c",
                "type": "geojson",
                "config": {
                  "dataId": "p078lxpeh",
                  "label": "Auto EV09",
                  "color": [
                    18,
                    147,
                    154
                  ],
                  "highlightColor": [
                    252,
                    242,
                    26,
                    255
                  ],
                  "columns": {
                    "geojson": "_geojson"
                  },
                  "isVisible": true,
                  "visConfig": {
                    "opacity": 0.8,
                    "strokeOpacity": 0.5,
                    "thickness": 1.5,
                    "strokeColor": [
                      172,
                      0,
                      0
                    ],
                    "colorRange": {
                      "name": "Global Warming",
                      "type": "sequential",
                      "category": "Uber",
                      "colors": [
                        "#5A1846",
                        "#900C3F",
                        "#C70039",
                        "#E3611C",
                        "#F1920E",
                        "#FFC300"
                      ]
                    },
                    "strokeColorRange": {
                      "name": "Global Warming",
                      "type": "sequential",
                      "category": "Uber",
                      "colors": [
                        "#5A1846",
                        "#900C3F",
                        "#C70039",
                        "#E3611C",
                        "#F1920E",
                        "#FFC300"
                      ]
                    },
                    "radius": 10,
                    "sizeRange": [
                      0,
                      10
                    ],
                    "radiusRange": [
                      0,
                      50
                    ],
                    "heightRange": [
                      0,
                      500
                    ],
                    "elevationScale": 5,
                    "enableElevationZoomFactor": true,
                    "stroked": true,
                    "filled": false,
                    "enable3d": false,
                    "wireframe": false
                  },
                  "hidden": false,
                  "textLabel": [
                    {
                      "field": null,
                      "color": [
                        255,
                        255,
                        255
                      ],
                      "size": 18,
                      "offset": [
                        0,
                        0
                      ],
                      "anchor": "start",
                      "alignment": "center"
                    }
                  ]
                },
                "visualChannels": {
                  "colorField": null,
                  "colorScale": "quantile",
                  "strokeColorField": null,
                  "strokeColorScale": "quantile",
                  "sizeField": null,
                  "sizeScale": "linear",
                  "heightField": null,
                  "heightScale": "linear",
                  "radiusField": null,
                  "radiusScale": "linear"
                }
              },
              {
                "id": "wu86wxj",
                "type": "geojson",
                "config": {
                  "dataId": "6gukea4jl",
                  "label": "Auto E05",
                  "color": [
                    221,
                    178,
                    124
                  ],
                  "highlightColor": [
                    252,
                    242,
                    26,
                    255
                  ],
                  "columns": {
                    "geojson": "_geojson"
                  },
                  "isVisible": true,
                  "visConfig": {
                    "opacity": 0.8,
                    "strokeOpacity": 0.5,
                    "thickness": 1.5,
                    "strokeColor": [
                      254,
                      137,
                      26
                    ],
                    "colorRange": {
                      "name": "Global Warming",
                      "type": "sequential",
                      "category": "Uber",
                      "colors": [
                        "#5A1846",
                        "#900C3F",
                        "#C70039",
                        "#E3611C",
                        "#F1920E",
                        "#FFC300"
                      ]
                    },
                    "strokeColorRange": {
                      "name": "Global Warming",
                      "type": "sequential",
                      "category": "Uber",
                      "colors": [
                        "#5A1846",
                        "#900C3F",
                        "#C70039",
                        "#E3611C",
                        "#F1920E",
                        "#FFC300"
                      ]
                    },
                    "radius": 10,
                    "sizeRange": [
                      0,
                      10
                    ],
                    "radiusRange": [
                      0,
                      50
                    ],
                    "heightRange": [
                      0,
                      500
                    ],
                    "elevationScale": 5,
                    "enableElevationZoomFactor": true,
                    "stroked": true,
                    "filled": false,
                    "enable3d": false,
                    "wireframe": false
                  },
                  "hidden": false,
                  "textLabel": [
                    {
                      "field": null,
                      "color": [
                        255,
                        255,
                        255
                      ],
                      "size": 18,
                      "offset": [
                        0,
                        0
                      ],
                      "anchor": "start",
                      "alignment": "center"
                    }
                  ]
                },
                "visualChannels": {
                  "colorField": null,
                  "colorScale": "quantile",
                  "strokeColorField": null,
                  "strokeColorScale": "quantile",
                  "sizeField": null,
                  "sizeScale": "linear",
                  "heightField": null,
                  "heightScale": "linear",
                  "radiusField": null,
                  "radiusScale": "linear"
                }
              }
            ],
            "interactionConfig": {
              "tooltip": {
                "fieldsToShow": {
                  "6eimmjib": [
                    {
                      "name": "prop0",
                      "format": null
                    }
                  ],
                  "zba9w1rnn": [
                    {
                      "name": "prop0",
                      "format": null
                    }
                  ],
                  "p078lxpeh": [
                    {
                      "name": "prop0",
                      "format": null
                    }
                  ],
                  "6gukea4jl": [
                    {
                      "name": "prop0",
                      "format": null
                    }
                  ],
                  "jpo3nqrxe": [
                    {
                      "name": "prop0",
                      "format": null
                    }
                  ],
                  "rbiikmwso": [
                    {
                      "name": "short name",
                      "format": null
                    },
                    {
                      "name": "battery to swap",
                      "format": null
                    }
                  ]
                },
                "compareMode": false,
                "compareType": "absolute",
                "enabled": true
              },
              "brush": {
                "size": 0.5,
                "enabled": false
              },
              "geocoder": {
                "enabled": false
              },
              "coordinate": {
                "enabled": false
              }
            },
            "layerBlending": "normal",
            "splitMaps": [],
            "animationConfig": {
              "currentTime": null,
              "speed": 1
            }
          },
          "mapState": {
            "bearing": 0,
            "dragRotate": false,
            "latitude": 45.51690670141785,
            "longitude": -73.5674859491925,
            "pitch": 0,
            "zoom": 13.949434099334896,
            "isSplit": false
          },
          "mapStyle": {
            "styleType": "light",
            "topLayerGroups": {},
            "visibleLayerGroups": {
              "label": true,
              "road": true,
              "border": false,
              "building": true,
              "water": true,
              "land": true,
              "3d building": false
            },
            "threeDBuildingColor": [
              218.82023004728686,
              223.47597962276103,
              223.47597962276103
            ],
            "mapStyles": {
              "6u2pwa8": {
                "accessToken": null,
                "custom": true,
                "icon": "https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/-122.3391,37.7922,9,0,0/400x300?access_token=pk.eyJ1IjoidWNmLW1hcGJveCIsImEiOiJja2tyMjNhcWIwc29sMnVzMThoZ3djNXhzIn0._hfBNwCD7pCU7RAMOq6vUQ&logo=false&attribution=false",
                "id": "6u2pwa8",
                "label": "Mapbox Streets",
                "url": "mapbox://styles/mapbox/streets-v11"
              }
            }
          }
        }
      }
};