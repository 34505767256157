import React from 'react';
import './info-panel-styles.css';
import { Button} from 'react-bootstrap';
import $ from 'jquery';
import Header from './OptionButton';
import Footer from './Footer';
import TabNav from './TabNav';
import Tab from './Tab';
import HorizontalScroll from './horizontalScroll.js';
import { confirmAlert } from 'react-confirm-alert';
//import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import './react-confirm-alert.css';
import ModalApp from './consoleStreamModal.js';
import {getNetworkStatus,getBatterySwapResource, getOperations, estimateQi, assignVeh, getRelocation, getRoute, getBatterySwap,
startAutoPilot, stopAutoPilot, pauseAutoPilot, resumeAutoPilot, getStations, getBatteryDispatchRoutes, getBatteryAllRoutes} from './Caller.js'

import {addDataToMap} from 'kepler.gl/actions';
import {connect} from 'react-redux';
import Processors from 'kepler.gl/processors';

import statusMapConfig from './configs/statusMapConfig.js';
import relocationMapConfig from './configs/relocationMapConfig.js';
import getRouteConfigs from './configs/routeConfigs.js';
import getBatterySwapConfig from './configs/batterySwapRoutesMapConfigs.js';

import getRouteConfigsDark from './configs/routeConfigsDark.js';
import getBatterySwapConfigDark from './configs/batterySwapRoutesMapConfigsDark.js';

import ListGroup from 'react-bootstrap/ListGroup';
import ResourceModalComponent from './ResourceModalComponent';
import SectorConfigModal from './SectorConfigModal';
import LogoPanel from './logoPanel';
import ControlPanel from './controlPanel';
import StatsPanel from './statsPanel';
import { DisplayModeContext } from './DisplayModeContext';
const API_URL = process.env.REACT_APP_AUDIENCE
console.log("API URL", API_URL)
const STATUS_URL = API_URL + "dispatcher/status_stream/";

var processRelocationResourceData = (resource) => {
    var newData = {};
    newData.inventaire = resource.data.properties["hresourceidentification.inventaire"];
    newData.name = resource.data["label"];
    newData.endShift = resource.data.properties["hresourceidentification.quart_travail"];
    newData.assigned_task_queue = resource.assinged_queue;
    newData.dispatched_task_queue = resource.dispatched_queue;
    // add an id for each task in the queues
    newData.assigned_task_queue.forEach((task, index) => {
        task.id = index;
    });
    newData.dispatched_task_queue.forEach((task, index) => {
        task.id = index;
    });
    return newData;
}

var processBatterySwapResourceData = (resource) => {
    var newData = {};
    newData.inventaire = resource.data.properties["hresourceidentification.inventaire_batterie"];
    newData.name = resource.data["label"];
    newData.endShift = resource.data.properties["hresourceidentification.quart_travail"];
    newData.assigned_task_queue = resource.assinged_queue;
    newData.dispatched_task_queue = resource.dispatched_queue;
    newData.assigned_task_queue.forEach((task, index) => {
        task.id = index;
    });
    newData.dispatched_task_queue.forEach((task, index) => {
        task.id = index;
    });
    return newData;
}


class SidePanel extends React.Component {

    colorMap = {
        "Ready": "YellowGreen",
        "Active": "Green",
        "Off": "Gray",
        "Error": "Red",
        "Starting": "GreenYellow",
        "Starting...": "GreenYellow",
        "Stopping...": "Red",
        "Stopping": "Red",
        "Paused": "Orange",
        "Unavailable": "Red",
    }
    
    showNetworkStatus = (obj) => {
        const callback = (data) => {
            var s = new TextDecoder().decode(data);
            s = JSON.parse(s).result;
            const csvData = Processors.processCsvData(s);
            obj.props.dispatch(
                addDataToMap({
                    datasets: {
                      info: {
                        label: "Station",
                        id: "network_status"
                      },
                      data: csvData
                    },
                    option: {
                      centerMap: false,
                      readOnly: true,
                      keepExistingConfig: false
                    },
                    info: {
                      title: "Network Status",
                      description: "Network Status"
                    },
                    config: statusMapConfig
                  })
            ); 
        };
        getNetworkStatus(callback);
    }

    showRelocation = (obj) => {
        const callback = (data) => {
            var s = new TextDecoder().decode(data);
            s = JSON.parse(s).result;
            const csvData = Processors.processCsvData(s);
            obj.props.dispatch(
                addDataToMap({
                    datasets: {
                      info: {
                        label: "Relocation",
                        id: "r81of017m"
                      },
                      data: csvData
                    },
                    option: {
                      centerMap: false,
                      readOnly: true,
                      keepExistingConfig: false
                    },
                    info: {
                      title: "Relocation Solution",
                      description: "Relocation Solution"
                    },
                    config: relocationMapConfig
                  })
            ); 
        };
        getRelocation(callback);
    }

    async showBatterySwap(obj) {
        const color = [ [110,0,120], [100,110,0], [10,90,20], [30, 80, 90], [30, 110, 5], [0,0,60]] 
        const callback = (data) => {
            //var s = new TextDecoder().decode(data);
            //const jsonData = JSON.parse(s);
            const jsonData = data;
            const routes = jsonData.result;
            const mapState = jsonData.network_state;
            const routesData = Object.values(routes).map( (route, index) => {
                return {
                    dataId: index.toString(),
                    color: color[index],
                    opacity: 0.5,
                    label: route['features'][0]['properties']['name'],
                    geojsonData: Processors.processGeojson(route),
                }
            })
            const mapStateData = Processors.processCsvData(mapState);
            obj.props.dispatch(
                addDataToMap({
                    datasets: routesData.map(route => {
                        return {
                            "info": {
                                "label": route.label,
                                "id": route.dataId
                            },
                            "data": route.geojsonData
                        }
                    }).concat([{"info": {"label": "Battery Swap Task", "id":"batterySwapTask"}, "data": mapStateData}]),
                    option: {
                      centerMap: false,
                      readOnly: true,
                      keepExistingConfig: false
                    },
                    info: {
                      title: "Battery Swap Plan",
                      description: "Battery swap operations planning."
                    },
                    config: getBatterySwapConfig(routesData)
                  })
            );
            console.log("dispatched")
            console.log(getBatterySwapConfig(routesData))
        }
        const d = await getBatterySwap();
        callback(d);
    }

    async showBatteryDispatches() {
        const color = [ [110,0,120], [100,110,0], [10,90,20], [30, 80, 90], [30, 110, 5], [0,0,60]] 
        const callback = (data) => {
            //var s = new TextDecoder().decode(data);
            //const jsonData = JSON.parse(s);
            const jsonData = data;
            const routes = jsonData.result;
            const mapState = jsonData.network_state;
            const routesData = Object.values(routes).map( (route, index) => {
                return {
                    dataId: index.toString(),
                    color: color[index],
                    opacity: 0.5,
                    label: route['features'][0]['properties']['name'],
                    geojsonData: Processors.processGeojson(route),
                }
            })
            const mapStateData = Processors.processCsvData(mapState);
            this.props.dispatch(
                addDataToMap({
                    datasets: routesData.map(route => {
                        return {
                            "info": {
                                "label": route.label,
                                "id": route.dataId
                            },
                            "data": route.geojsonData
                        }
                    }).concat([{"info": {"label": "Battery Swap Task", "id":"batterySwapTask"}, "data": mapStateData}]),
                    option: {
                      centerMap: false,
                      readOnly: true,
                      keepExistingConfig: false
                    },
                    info: {
                      title: "Battery Swap Plan",
                      description: "Battery swap operations planning."
                    },
                    config: this.props.displayMode==='light'? getBatterySwapConfig(routesData) : getBatterySwapConfigDark(routesData)
                  })
            );
            console.log("dispatched")
            console.log(getBatterySwapConfig(routesData))
        }
        const d = await getBatteryDispatchRoutes();
        callback(d);
    }

    async showBatteryAll() {
        const color = [ [110,0,120], [100,110,0], [10,90,20], [30, 80, 90], 
                        [30, 110, 5], [0,0,60], [10,40,5], [250,230,10], [110,90,90],
                        [70,20,150], [170,10,170], [110,20,20], [40,145,25]] 
        const callback = (data) => {
            //var s = new TextDecoder().decode(data);
            //const jsonData = JSON.parse(s);
            const jsonData = data;
            const routes = jsonData.result;
            const mapState = jsonData.network_state;
            console.log(Object.values(routes).map( (route, index) => {
                return route['features'][0]['properties']['name'];
            }));
            const names = Object.values(routes).map( (route, index) => {
                const name = route['features'][0]['properties']['name']
                return name.split(" dispatched")[0];
            });
            const colors = {};
            names.forEach( (name, index) => {
                colors[name] = color[index];
            })
            const opacities = Object.values(routes).map( (route, index) => {
                const name = route['features'][0]['properties']['name'];
                const splits = name.split(" dispatched");
                if (splits.length > 1){
                    return 0.95;
                } 
                else {
                    return 0.35;
                }
            });
            console.log(names);
            console.log(colors);
            console.log(opacities);
            const routesData = Object.values(routes).map( (route, index) => {
                return {
                    dataId: index.toString(),
                    color: colors[names[index]],
                    opacity: opacities[index],
                    label: route['features'][0]['properties']['name'],
                    geojsonData: Processors.processGeojson(route),
                }
            })
            const mapStateData = Processors.processCsvData(mapState);
            this.props.dispatch(
                addDataToMap({
                    datasets: routesData.map(route => {
                        return {
                            "info": {
                                "label": route.label,
                                "id": route.dataId
                            },
                            "data": route.geojsonData
                        }
                    }).concat([{"info": {"label": "Battery Swap Task", "id":"batterySwapTask"}, "data": mapStateData}]),
                    option: {
                      centerMap: false,
                      readOnly: true,
                      keepExistingConfig: false
                    },
                    info: {
                      title: "Battery Swap Plan",
                      description: "Battery swap operations planning."
                    },
                    config: this.props.displayMode==='light'? getBatterySwapConfig(routesData) : getBatterySwapConfigDark(routesData)
                  })
            );
            console.log("dispatched")
            console.log(getBatterySwapConfig(routesData))
        }
        const d = await getBatteryAllRoutes();
        callback(d);
    }


    updateStatusData(obj) {
        const pointConfig = {
            radius: 1,
            fixedRadius: false,
            opacity: 0.7,
            outline: false,
            colorRange: {
                colors: [
                      "#FF4A80",
                      "#F59091",
                      "#ffbec9",
                      "#BEFFC3",
                      "#91E07A",
                      "#33FFA8"
                ],
                reversed: false
            },
            radiusRange: [ 0, 200 ],
            "hi-precision": false
          };
        const visualChannels = {
        colorField: {
            "name": "num bikes available",
            "type": "integer"
        },
        colorScale: "quantile",
        sizeField: {
            "name": "num bikes available",
            "type": "integer"
        }        
        };   
        const layerConfig = {
                dataId: "qi_data",
                label: "Estimation des quantités optimales à relocaliser",
                columns: {
                    "lat": "lat",
                    "lng": "lon",
                    "altitude": null
                },
                isVisible: true,
                visConfig: pointConfig,
                "textLabel": [
                {
                    "field": {
                    "name": "num bikes available",
                    "type": "integer"
                    },
                    "color": [
                    53,
                    166,
                    204
                    ],
                    "size": 18,
                    "offset": [
                    0,
                    0
                    ],
                    "anchor": "start",
                    "alignment": "center"
                }
                ]
        };
        const config_point = {
        mapStyle: {styleType: 'light'},
        id: "qi_data",
        type: "point",
        config: layerConfig,
        visualChannels
        };
        const settings = config_point;
        const interactionConfig = {
        tooltip: {
                fieldsToShow: {
                    "qi_data": [
                    "Short name",
                    "num bikes available",
                    ]
                },
                enabled: true
            },
            geocoder : {
            enabled : true
            },
            brush: {
                enabled: false
            }
        };
        const config = {
        
            version: "v1",
            config: {
            mapStyle: {
                "styleType": "392mef",
                "topLayerGroups": {},
                "visibleLayerGroups": {
                "label": true,
                "road": true,
                "building": true,
                "water": true,
                "land": true
                },
                "threeDBuildingColor": [
                194.6103322548211,
                191.81688250953655,
                185.2988331038727
                ],
                "mapStyles": {
                "392mef": {
                    "accessToken": "",
                    "custom": true,
                    "icon": "https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/-122.3391,37.7922,9,0,0/400x300?access_token=pk.eyJ1IjoidWNmLW1hcGJveCIsImEiOiJja2tyMjNhcWIwc29sMnVzMThoZ3djNXhzIn0._hfBNwCD7pCU7RAMOq6vUQ&logo=false&attribution=false",
                    "id": "392mef",
                    "label": "Mapbox Streets",
                    "url": "mapbox://styles/mapbox/streets-v11"
                }
                }
            },
            visState: {
                filters: [{
                "dataId": [
                    "qi_data"
                ],
                "id": "qi_data",
                "name": [
                    "num bikes available"
                ],
                "type": "range",
                "value": [
                    0,
                    231
                ],
                "enlarged": false,
                "plotType": "histogram",
                "animationWindow": "free",
                "yAxis": null,
                "speed": 1
                }],
                layers: [ settings ],
                interactionConfig,
                layerBlending: "normal",
                splitMaps: []
            },
            }
        };
    
        const callback = (data) => {
            const csvData = Processors.processCsvData(data);
            obj.props.dispatch(
                addDataToMap({
                  datasets: {
                    info: {
                      label: 'Estimation des quantités optimales à relocaliser',
                      id: 'qi_data'
                    },
                    data: csvData
                  },
                  option: {
                    centerMap: true,
                    readOnly: true,
                    keepExistingConfig: false
                  },
                  info: {
                    title: 'Estimation',
                    description: 'Estimation des quantités optimales à relocaliser'
                  },
                  config: config
                })
            );
        }
    }

    async  startAutoPilot() { 
        // TODO catch la reponse si prop
        
        this.setState( {resourceModal: true});
        
        //this.setState({autoPilotStatus: "MediumSeaGreen",
        //                       autoPilotState: "Starting..."});
        
    }
    stopAutoPilot = (obj) => {
        if (this.state.autoPilotState !== "Off") {
            const callback = (data) => {
                return null
            }
            stopAutoPilot(callback);
        }
    }

    pauseAutoPilot = (obj) => {
        if (this.state.autoPilotState === "Active") {
            const callback = (data) => {
                this.setState({autoPilotStatus: "Orange",
                                autoPilotState: "Paused"});
            }
            pauseAutoPilot(callback);
        }
    }

    resumeAutoPilot = (obj) => {
        if (this.state.autoPilotState == "Paused" || 
            this.state.autoPilotState == "Ready") {
            const callback = (data) => {
                this.setState({autoPilotStatus: "MediumSeaGreen",
                                autoPilotState: "Active"});
            }   
            resumeAutoPilot(callback);
        }    
    }

    pauseResumeAutoPilot = (obj) => {
        console.log("PauseResumeAutoPilot", this.state.autoPilotState);
        if (this.state.autoPilotState === "Paused" || 
            this.state.autoPilotState === "Ready") {
            this.resumeAutoPilot();
        } else {
            this.pauseAutoPilot();
        }
    }

    initialize_status_stream = () =>  {
        var eventsource = new EventSource(STATUS_URL);
        eventsource.onopen = function() {
        }
        eventsource.onerror = function(e) {
            console.log("Error: " + e);
        }
        eventsource.onmessage = (e) => {
            var data = JSON.parse(e.data);
            // set the state of the auto pilot attribute 'autoPilotState'
            // with the value of the attribute 'status' of the event
            this.setState({autoPilotState: data.state });
        }
    }

    constructor(props) {
        super(props);
        this.state = {
          showSectorConfigModal: false,
          resourceModal: false,
          selected: 'Demande',
          text1: "allo",
          params: {},
          assign_params : {},
          loadedRelocationResources : [],
          loadedBatterySwapResources : [],
          autoPilotStatus: "Tomato",
          autoPilotState: "",
          showTrace: false,
          trace: "",
        }
        this.showBatteryAll = this.showBatteryAll.bind(this);
        this.submitStop =this.submitStop.bind(this)
        this.resourcesState = React.createRef();
        this.closeResourceModal = this.closeResourceModal.bind(this);
        this.initialize_status_stream();
        this.pauseResumeAutoPilot = this.pauseResumeAutoPilot.bind(this)
        this.startAutoPilot = this.startAutoPilot.bind(this)
        this.openSectorConfigModal = this.openSectorConfigModal.bind(this);
        this.closeSectorConfigModal = this.closeSectorConfigModal.bind(this);
        this.showBatteryDispatches = this.showBatteryDispatches.bind(this);
        this.openSectorConfigModal = this.openSectorConfigModal.bind(this);

    }
    openSectorConfigModal(){
        this.setState({showSectorConfigModal: true});
    }
    closeSectorConfigModal(){
        this.setState({showSectorConfigModal: false});
    }
    closeResourceModal(){
        this.setState({resourceModal: false});
    }

    showTrace = (show) => {
        this.setState({showTrace: show});
    }

    setSelected = (tab) => {
    this.setState({ selected: tab });
    }

    getTrace = () => {
        return String(this.state.trace);
    }

    PauseResumeText = () => {
        if (this.state.autoPilotState === "Active"){
            return "Pause"
        }
        else{
            return "Resume"
        }
    }

    submitStop = () => {
        if (this.state.autoPilotState==="Off"){
            return null;
        }
        confirmAlert({
          title: 'Confirm to stop the auto-pilot',
          message: 'Are you sure to stop the auto-pilot? Current battery swap routes will be lost.',
          buttons: [
            {
              label: 'Confirm',
              onClick: () => this.stopAutoPilot(this)
            },
            {
              label: 'Cancel',
              onClick: () => "" 
            }
          ]
        });
      };
      static contextType = DisplayModeContext;
    render (){
    return (
        <div className="side-panel">
            {this.state.resourceModal && <ResourceModalComponent close={this.closeResourceModal}  autoPilotState={this.state.autoPilotState} ></ResourceModalComponent>}
            {this.state.showSectorConfigModal && <SectorConfigModal show={this.state.showSectorConfigModal} handleClose={this.closeSectorConfigModal}></SectorConfigModal>}
            <LogoPanel/>
            <StatsPanel autoPilotState={this.state.autoPilotState} ></StatsPanel>
            <ControlPanel autoPilotState={this.state.autoPilotState} restartAction={this.props.restartAction} startAutoPilot={this.startAutoPilot} pauseResumeAutoPilot={this.pauseResumeAutoPilot} submitStop ={this.submitStop} showBatteryDispatches={this.showBatteryDispatches} showBatteryAll={this.showBatteryAll} openSectorConfigModal={this.openSectorConfigModal} />
             { !this.props.bottomModalShowStatus && <Button onClick={() => {this.props.bottomModalShow()}}>Show Bottom Menu </Button> }<br/>
               
               
        </div>);
    }
};

//export default InfoPanel;

const mapStateToProps = state => state;
const dispatchToProps = dispatch => ({dispatch});

export default connect(mapStateToProps, dispatchToProps)(SidePanel);
