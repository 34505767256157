import React, { Component } from 'react';
import { BottomMenu } from './BottomMenuComponent';
import {Tabs, Tab, CloseButton} from 'react-bootstrap';
import './BottomMenuV2.css';
import { TraceConsole } from './TraceConsole';

const HOST = process.env.REACT_APP_AUDIENCE;

export class BottomMenuV2 extends Component {
    
    messagesEndRef = React.createRef()

    constructor(props) {
      super(props);
      this.child = React.createRef();
    }

    handleTabsSelect = (firstTab, lastTab) => {
      if (firstTab ==='trace'){ 
        //this.child.current.scrollToBottom();
        this.scrollToBottom();
      }
    }
    scrollToBottom = () => {
      this.messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
    }
    render() { 
        return (
          <div className='bottom-info-panel'>
              {/*<div className='close-div' onClick={this.props.bottomModalShow}><CloseButton /></div>*/}
              <Tabs 
            id="bottomComponent"
            className="mb-3"
            onSelect={this.handleTabsSelect}
          >
            <Tab eventKey="resource" title="Available Resources" >
              <BottomMenu onChangeResource={this.props.onChangeResource} handleChangeArea={this.props.handleChangeArea} resources={this.props.resources}/>
            </Tab>
            <Tab eventKey="trace" title="Trace Console">
              <TraceConsole ref={this.child}/>
              <div ref={this.messagesEndRef}/>
            </Tab>

          </Tabs>
          
            </div>
           
        );
    }
}