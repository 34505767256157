  
import logo from './img/battery_swap_logo_fr_BIXI.png';
import './info-panel-styles.css';

function LogoPanel () {
	
	return	(<div id='logo-panel'>
			<img id="logo" width='300x' src={logo} alt=''/>
			
		    </div>)
	
}

export default LogoPanel;