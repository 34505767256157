export default {
    "version": "v1",
    "config": {
      "visState": {
        "filters": [
          {
            "dataId": [
              "r81of017m"
            ],
            "id": "fkl6d8xrh",
            "name": [
              "abs_min_to_relocated"
            ],
            "type": "range",
            "value": [
              1,
              50
            ],
            "enlarged": false,
            "plotType": "histogram",
            "animationWindow": "free",
            "yAxis": null,
            "speed": 1
          },
          {
            "dataId": [
              "r81of017m"
            ],
            "id": "akoo0x2byy",
            "name": [
              "lat"
            ],
            "type": "range",
            "value": [
              41.96,
              45.66
            ],
            "enlarged": false,
            "plotType": "histogram",
            "animationWindow": "free",
            "yAxis": null,
            "speed": 1
          }
        ]

        ,
        "layers": [
          {
            "id": "2p8sznf",
            "type": "point",
            "config": {
              "dataId": "r81of017m",
              "label": "Point",
              "color": [
                34,
                63,
                154
              ],
              "highlightColor": [
                252,
                242,
                26,
                255
              ],
              "columns": {
                "lat": "lat",
                "lng": "lon",
                "altitude": null
              },
              "isVisible": true,
              "visConfig": {
                "radius": 10,
                "fixedRadius": false,
                "opacity": 0.65,
                "outline": false,
                "thickness": 2,
                "strokeColor": null,
                "colorRange": {
                  "name": "Custom Palette",
                  "type": "custom",
                  "category": "Custom",
                  "colors": [
                    "#3ad182",
                    "#91cf60",
                    "#d9ef8b",
                    "#fee08b",
                    "#fc8d59",
                    "#e75d56"
                  ]
                },
                "strokeColorRange": {
                  "name": "Global Warming",
                  "type": "sequential",
                  "category": "Uber",
                  "colors": [
                    "#5A1846",
                    "#900C3F",
                    "#C70039",
                    "#E3611C",
                    "#F1920E",
                    "#FFC300"
                  ]
                },
                "radiusRange": [
                  13,
                  63
                ],
                "filled": true
              },
              "hidden": false,
              "textLabel": [
                {
                  "field": {
                    "name": "relocated_bike",
                    "type": "real"
                  },
                  "color": [
                    38,
                    71,
                    172
                  ],
                  "size": 18,
                  "offset": [
                    0,
                    0
                  ],
                  "anchor": "start",
                  "alignment": "center"
                }
              ]
            },
            "visualChannels": {
              "colorField": {
                "name": "color",
                "type": "real"
              },
              "colorScale": "quantize",
              "strokeColorField": null,
              "strokeColorScale": "quantile",
              "sizeField": {
                "name": "abs_min_to_relocated",
                "type": "real"
              },
              "sizeScale": "sqrt"
            }
          }
        ],
        "interactionConfig": {
          "tooltip": {
            "fieldsToShow": {
              "r81of017m": [
                {
                  "name": "short name",
                  "format": null
                },
                {
                  "name": "relocated_bike",
                  "format": null
                },
                {
                  "name": "lower",
                  "format": null
                },
                {
                  "name": "upper",
                  "format": null
                },
                {
                  "name": "initial",
                  "format": null
                },
                {
                  "name": "capacity",
                  "format": null
                }
              ]
            },
            "compareMode": false,
            "compareType": "absolute",
            "enabled": true
          },
          "brush": {
            "size": 0.5,
            "enabled": false
          },
          "geocoder": {
            "enabled": false
          },
          "coordinate": {
            "enabled": false
          }
        },
        "layerBlending": "normal",
        "splitMaps": [],
        "animationConfig": {
          "currentTime": null,
          "speed": 1
        }
      },
      "mapState": {
        "bearing": 0,
        "dragRotate": false,
        "latitude": 45.512706400329684,
        "longitude": -73.58143782345446,
        "pitch": 0,
        "zoom": 13.35845023266708,
        "isSplit": false
      },
      "mapStyle": {
        "styleType": "light",
        "topLayerGroups": {},
        "visibleLayerGroups": {
          "label": true,
          "road": true,
          "border": false,
          "building": true,
          "water": true,
          "land": true,
          "3d building": false
        },
        "threeDBuildingColor": [
          218.82023004728686,
          223.47597962276103,
          223.47597962276103
        ],
        "mapStyles": {}
      }
    }
  }