export default {
  "version": "v1",
  "config": {
    "visState": {
      "filters": [
        {
          "dataId": [
            "network_status"
          ],
          "id": "yl4qgp5td",
          "name": [
            "lat"
          ],
          "type": "range",
          "value": [
            42.57,
            45.66
          ],
          "enlarged": false,
          "plotType": "histogram",
          "animationWindow": "free",
          "yAxis": null,
          "speed": 1
        },
        {
          "dataId": [
            "network_status"
          ],
          "id": "aynomdk1",
          "name": [
            "lon"
          ],
          "type": "range",
          "value": [
            -73.75,
            -69.76
          ],
          "enlarged": false,
          "plotType": "histogram",
          "animationWindow": "free",
          "yAxis": null,
          "speed": 1
        }
      ],
      "layers": [
        {
          "id": "l5y4su",
          "type": "point",
          "config": {
            "dataId": "network_status",
            "label": "Station",
            "color": [
              255,
              203,
              153
            ],
            "highlightColor": [
              252,
              242,
              26,
              255
            ],
            "columns": {
              "lat": "lat",
              "lng": "lon",
              "altitude": null
            },
            "isVisible": true,
            "visConfig": {
              "radius": 10,
              "fixedRadius": false,
              "opacity": 0.8,
              "outline": false,
              "thickness": 2,
              "strokeColor": null,
              "colorRange": {
                "name": "ColorBrewer RdYlGn-6",
                "type": "diverging",
                "category": "ColorBrewer",
                "colors": [
                  "#d73027",
                  "#fc8d59",
                  "#fee08b",
                  "#d9ef8b",
                  "#91cf60",
                  "#1a9850"
                ]
              },
              "strokeColorRange": {
                "name": "Global Warming",
                "type": "sequential",
                "category": "Uber",
                "colors": [
                  "#5A1846",
                  "#900C3F",
                  "#C70039",
                  "#E3611C",
                  "#F1920E",
                  "#FFC300"
                ]
              },
              "radiusRange": [
                3,
                48.2
              ],
              "filled": true
            },
            "hidden": false,
            "textLabel": [
              {
                "field": null,
                "color": [
                  255,
                  255,
                  255
                ],
                "size": 18,
                "offset": [
                  0,
                  0
                ],
                "anchor": "start",
                "alignment": "center"
              }
            ]
          },
          "visualChannels": {
            "colorField": {
              "name": "num bikes available",
              "type": "integer"
            },
            "colorScale": "quantile",
            "strokeColorField": null,
            "strokeColorScale": "quantile",
            "sizeField": {
              "name": "capacity",
              "type": "integer"
            },
            "sizeScale": "sqrt"
          }
        }
      ],
      "interactionConfig": {
        "tooltip": {
          "fieldsToShow": {
            "p9yoplt9h": [
              {
                "name": "short name",
                "format": null
              },
              {
                "name": "num bikes available",
                "format": null
              },
              {
                "name": "capacity",
                "format": null
              },
              {
                "name": "% full",
                "format": null
              }
            ]
          },
          "compareMode": false,
          "compareType": "absolute",
          "enabled": true
        },
        "brush": {
          "size": 0.5,
          "enabled": false
        },
        "geocoder": {
          "enabled": false
        },
        "coordinate": {
          "enabled": false
        }
      },
      "layerBlending": "normal",
      "splitMaps": [],
      "animationConfig": {
        "currentTime": null,
        "speed": 1
      }
    },
    "mapState": {
      "bearing": 0,
      "dragRotate": false,
      "latitude": 45.52191814866871,
      "longitude": -73.5851007698384,
      "pitch": 0,
      "zoom": 13.69348827833445,
      "isSplit": false
    },
    "mapStyle": {
      "styleType": "dark",
      "topLayerGroups": {},
      "visibleLayerGroups": {
        "label": true,
        "road": true,
        "border": false,
        "building": true,
        "water": true,
        "land": true,
        "3d building": false
      },
      "threeDBuildingColor": [
        218.82023004728686,
        223.47597962276103,
        223.47597962276103
      ],
      "mapStyles": {}
    }
  }
}