  
import React  from 'react';
import {getBatterySwapConfig} from './Caller.js'
import { useState } from "react";
import OptionsModal from './OptionsModalComponent';
import { Button } from 'react-bootstrap';
import { RiSettings4Fill } from 'react-icons/ri';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const renderTooltip = (props,message) => (
      <Tooltip id="button-tooltip" {...props}>
        {message}
      </Tooltip>
    );
function OptionButton (props) {
	const [show, setShow] = useState(false);
	const [options, setOptions] = useState([]);
	async function  handleShow(){
		const rep = await getBatterySwapConfig();
		setOptions(rep.context);
		setShow(true)};
	const handleClose = () => setShow(false);
	
	return	(<div className="control-button">
				<OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip(props,"Settings")}
                        >
                     			<Button  id="settings-btn" onClick={handleShow}><RiSettings4Fill/></Button>

                </OverlayTrigger>
		
			{show && <OptionsModal show={show} close={handleClose}  config={options}></OptionsModal> }


		</div>)
	
}

export default OptionButton;