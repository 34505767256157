export default function getRouteConfig(routeData) {
    return {
        "id": routeData.dataId,
        "type": "geojson",
        "config": {
          "dataId": routeData.dataId,
          "label": routeData.label,
          "color": [
            255,
            203,
            153
          ],
          "highlightColor": [
            252,
            242,
            26,
            255
          ],
          "columns": {
            "geojson": "_geojson"
          },
          "isVisible": true,
          "visConfig": {
            "opacity": routeData.opacity,
            "strokeOpacity": routeData.opacity,
            "thickness": 1 + routeData.opacity,
            "strokeColor": routeData.color,
            "colorRange": {
              "name": "Global Warming",
              "type": "sequential",
              "category": "Uber",
              "colors": [
                "#5A1846",
                "#900C3F",
                "#C70039",
                "#E3611C",
                "#F1920E",
                "#FFC300"
              ]
            },
            "strokeColorRange": {
              "name": "Custom Palette",
              "type": "custom",
              "category": "Custom",
              "colors": [
                "#e6ab02",
                "#66a61e",
                "#e7298a",
                "#7570b3",
                "#d95f02",
                "#1b9e77",
                "#ff2424"
              ],
              "reversed": true
            },
            "radius": 10,
            "sizeRange": [
              0,
              10
            ],
            "radiusRange": [
              0,
              50
            ],
            "heightRange": [
              0,
              500
            ],
            "elevationScale": 5,
            "enableElevationZoomFactor": true,
            "stroked": true,
            "filled": false,
            "enable3d": false,
            "wireframe": false
          },
          "hidden": false,
          "textLabel": [
            {
              "field": null,
              "color": [
                255,
                255,
                255
              ],
              "size": 18,
              "offset": [
                0,
                0
              ],
              "anchor": "start",
              "alignment": "center"
            }
          ]
        },
        "visualChannels": {
          "colorField": null,
          "colorScale": "quantile",
          "strokeColorField": null,
          "strokeColorScale": "quantile",
          "sizeField": null,
          "sizeScale": "linear",
          "heightField": null,
          "heightScale": "linear",
          "radiusField": null,
          "radiusScale": "linear"
        }
      }
}