import React, { Component } from 'react';

import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import KeplerGl from 'kepler.gl';
import {addDataToMap} from 'kepler.gl/actions';
import {connect} from 'react-redux';
import KeplerGlSchema from 'kepler.gl/schemas';
import downloadJsonFile from "./file-download";
// Kepler.gl Data processing APIs
import Processors from 'kepler.gl/processors';

import {getNetworkStatus} from './Caller.js'
import statusMapConfigLight from './configs/statusMapConfigLight';
import statusMapConfigDark from './configs/statusMapConfigDark';
import { DisplayModeContext } from './DisplayModeContext';


class Map extends Component {

    componentDidMount(){
        const callback = (data) => {
          this.props.dispatch(
              addDataToMap({
                  datasets: {
                    info: {
                      label: "Station",
                      id: "p9yoplt9h"
                    },
                    data: {}
                  },
                  option: {
                    centerMap: false,
                    readOnly: true,
                    keepExistingConfig: false
                  },
                  info: {
                    title: "Network Status",
                    description: "Network Status"
                  },
                  config: this.context.displayMode === 'light' ?  statusMapConfigLight : statusMapConfigDark
                })
          ); 
      };
      callback("{result: ''}");
    }

    getMapConfig() {
      // retrieve kepler.gl store
      const {keplerGl} = this.props;
      // retrieve current kepler.gl instance store
      const {map} = keplerGl;
  
      // create the config object
      return KeplerGlSchema.getConfigToSave(map);
    }
  
    // This method is used as reference to show how to export the current kepler.gl instance configuration
    // Once exported the configuration can be imported using parseSavedConfig or load method from KeplerGlSchema
    exportMapConfig = () => {
      // create the config object
      const mapConfig = this.getMapConfig();
      // save it as a json file
      downloadJsonFile(mapConfig, 'kepler.gl.json');
    };
  
    // Created to show how to replace dataset with new data and keeping the same configuration
    replaceData = () => {
      // Create dataset structure      
      const sampleTripData = {
        fields: [
          {name: 'tpep_pickup_datetime', format: 'YYYY-M-D H:m:s', type: 'timestamp'},
          {name: 'pickup_longitude', format: '', type: 'real'},
          {name: 'pickup_latitude', format: '', type: 'real'}
        ],
        rows: [
          ['2015-01-15 19:05:39 +00:00', -73.99389648, 40.75011063],
          ['2015-01-15 19:05:39 +00:00', -73.97642517, 40.73981094],
        ]
       };
      
      // read the current configuration
      const config = this.getMapConfig();
  
      // addDataToMap action to inject dataset into kepler.gl instance
      this.props.dispatch(addDataToMap({datasets: sampleTripData, config}));
    };
    static contextType = DisplayModeContext;
    render() { 
        return (
            <div style={{position: 'absolute', width: '100%', height: '100%', minHeight: '70vh'}}>
              <AutoSizer>
                {({height, width}) => (
                  <KeplerGl
                    mapboxApiAccessToken={"pk.eyJ1IjoiZ3VjY2lpbWFkIiwiYSI6ImNrbWt5Y2ZmOTE1dzcycW11ZjB1d2VwMmMifQ.kjXkXW06XjwUc-7_tkDkRA"}
                    id="map"
                    width={width}
                    height={height}
                  />
                )}
              </AutoSizer>
            </div>
        );
    }
}

const mapStateToProps = state => state;
const dispatchToProps = dispatch => ({dispatch});

export default connect(mapStateToProps, dispatchToProps)(Map);