import React, {Component, Fragment} from 'react';
// import react bootstrap;
import Modal from 'react-bootstrap/Modal'
import {Button} from 'react-bootstrap';
import './info-panel-styles.css';
import Form from 'react-bootstrap/Form'
import {verificateCredentialGET} from './Caller.js'

const MyVerticallyCenteredModal = (props) =>{
    return (
      <Modal
        size="lg" 
        show={props.show} 
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Auto-Repartition -- Development Demo
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Enter server and password key.
        </p>
        <Form.Control id="server" type="text" placeholder="Server Name" /> <br/>
        <Form.Control id="key" type="password" placeholder="Password Key" /> <br/>

      <Button variant="primary" onClick={()=> {
            // Get the values of the form
            const server = document.getElementById("server").value;
            const key = document.getElementById("key").value;
            console.log("Clicked on button");
            // Call the verificateCredential function
            var callback = (data) => {
                // data['status'] == 'valid'
                var s = new TextDecoder().decode(data);
                data = JSON.parse(s);
                if (data['status'] == 'valid') {
                    props.onHide(false);
                }
                else {
                    alert("Invalid credentials");
                }
            }
            verificateCredentialGET(server, key, callback);             
          } }>
      Connect
      </Button>
      </Modal.Body>

    </Modal>
    );
};

export default MyVerticallyCenteredModal;