import './App.css';
import './displayMode.css';
import React, {Component, Fragment} from 'react';
import Map from './map.js';
import store from './reducers.js';
import {Provider} from 'react-redux';
import SidePanel from './sidePanel';
import ModalApp from './CenteredModal.js';
import { BottomMenu } from './BottomMenuComponent'
import { BottomMenuV2 } from './BottomMenuV2'
import { getBatterySwapResource, sendBatterySwapResource, sendRestart } from './Caller';
import { apiFormatWorkingTime, displayTime } from './helper/TimeHelper';
import { DisplayModeContext } from './DisplayModeContext';
class App extends Component {

  constructor(props) {
    super(props);
    this.toggleMode = () => {
      let displayMode = localStorage.getItem('displayMode') 
      if(displayMode === null){
        displayMode = 'light';
      }
      displayMode = displayMode === 'light' ? 'night': 'light';
      localStorage.setItem('displayMode',displayMode);
      window.location.reload(); 
      
      
    };
    let displayMode = localStorage.getItem('displayMode')
    if(displayMode === null){
      displayMode = 'light';
    }
    this.state = {
      loginValid: false,
      modalShow: true,
      stateBottomModalShow: true,
      resources: [],
      displayMode: displayMode,
      toggleMode: this.toggleMode
    }
      this.bottomModalShow = this.bottomModalShow.bind(this);
      this.handleRestart = this.handleRestart.bind(this);
      this.handleChangeArea = this.handleChangeArea.bind(this);
      this.handleChangeResource = this.handleChangeResource.bind(this);
  }

  async  componentDidMount(){
   await this.initResourcesInfo();
  }
  async initResourcesInfo(){
    const res = await getBatterySwapResource();
    for(let resource of res.context){
      resource.working_time = displayTime(resource.working_time);
    }
     this.setState({resources: res.context});
    
  }
  async handleChangeResource(resources){
    const resourcesCopy = [...resources];
    for(let resource of resourcesCopy){
      resource.working_time = displayTime(resource.working_time);
    }
    this.setState({resources:resourcesCopy})
  }

  async handleRestart(event){
      const resourcesCopy = [...this.state.resources];
      for(let resource of resourcesCopy){
        resource.working_time = apiFormatWorkingTime(resource.working_time);
        resource.selected = "";
      }
      const status = await sendBatterySwapResource({context: resourcesCopy });
      for(let resource of resourcesCopy){
        resource.working_time = displayTime(resource.working_time);
      }
      this.setState({resources:resourcesCopy})
      if (status === 204){
        await sendRestart();
      }
      event.preventDefault();
    }

    handleChangeArea(index,areas){
      const resourcesCopy = [...this.state.resources];
      resourcesCopy[index].neighbors = areas
      this.setState({resources: resourcesCopy})
      console.log(areas);

      console.log("------------------1---------------------------");
    console.log(resourcesCopy);
    }
  setModalShow = (show) => { this.setState({modalShow: show}); this.setState({loginValid: true})};
  bottomModalShow(){ 
    console.log(this);
    this.setState({stateBottomModalShow: !this.state.stateBottomModalShow})};
  render() {
    if (this.state.loginValid){
      return (
        <DisplayModeContext.Provider value={this.state}>
        <div id={this.state.displayMode} >
        <Provider store={store}>
          
          <link rel="preconnect" href="https://fonts.gstatic.com"/>
          <link href="https://fonts.googleapis.com/css2?family=Amiko:wght@700&family=Oswald:wght@600&family=Roboto:wght@700&display=swap" rel="stylesheet"></link>
          
          <link rel="preconnect" href="https://fonts.gstatic.com"/>
          <link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap" rel="stylesheet"></link>
          <ModalApp show={this.state.modalShow}  onHide={this.setModalShow}/>
         
          <Fragment >  
            {this.state.stateBottomModalShow && <BottomMenuV2  handleChangeArea={this.handleChangeArea} onChangeResource={this.handleChangeResource} resources={this.state.resources} bottomModalShow={this.bottomModalShow}/>}
            <SidePanel restartAction={this.handleRestart} displayMode={this.state.displayMode} bottomModalShowStatus={this.state.stateBottomModalShow} bottomModalShow={this.bottomModalShow} />
            <Map/>
            
          </Fragment>
        </Provider>
        </div>
        </DisplayModeContext.Provider>
      )
      } else {
        return (
          <DisplayModeContext.Provider value={this.state}>
          <div id={this.state.displayMode} >
          <Provider store={store}>
            
            <link rel="preconnect" href="https://fonts.gstatic.com"/>
            <link href="https://fonts.googleapis.com/css2?family=Amiko:wght@700&family=Oswald:wght@600&family=Roboto:wght@700&display=swap" rel="stylesheet"></link>
            
            <link rel="preconnect" href="https://fonts.gstatic.com"/>
            <link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap" rel="stylesheet"></link>
            <ModalApp show={this.state.modalShow}  onHide={this.setModalShow}/>
          </Provider>
          </div>
          </DisplayModeContext.Provider>
        )
      }
    }
  }

export default App;
