import { Component } from "react";
import { Button} from "react-bootstrap";
import OptionButton from "./OptionButton";
import { FaPowerOff,FaRoute, FaStop, FaPause, FaPlay} from 'react-icons/fa';
import { SiReactrouter} from 'react-icons/si';
import { FcDataConfiguration} from 'react-icons/fc';
import { GrUpdate} from 'react-icons/gr';
import ThemeTogglerButton from "./DisplayTogglerButton";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

    const renderTooltip = (props,message) => (
      <Tooltip id="button-tooltip" {...props}>
        {message}
      </Tooltip>
    );
    
export default class ControlPanel extends Component {
    
    render(){
        return (
            <div id="control-panel">
                <div id="control-btn-container">
                {this.props.autoPilotState ===  'Active'|| this.props.autoPilotState === 'Ready' || this.props.autoPilotState === 'Paused' ? 
                        <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip(this.props,"Update")}
                        >
                            <Button style={{background:this.props.autoPilotState === "Off" ? '#EAEAEA':'#affaaa'}}  className="control-button" id='Update'  onClick={(event) => {this.props.restartAction(event)}}><GrUpdate color='white'/></Button> 
                        </OverlayTrigger>
                    :
                    <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip(this.props,"Start")}
                        >
                        <Button className="control-button" style={{background:this.props.autoPilotState === "Off" ? '#affaaa':'#EAEAEA'}}   id='Start'  onClick={() => {this.props.startAutoPilot()}}><FaPowerOff/></Button>
                    </OverlayTrigger>
                }
                 <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip(this.props,"Pause/Resume")}
                        >
                <Button className="control-button"style={{background:["Ready", "Paused", "Active"].includes(this.props.autoPilotState) ? (this.props.autoPilotState==="Ready" ? "#7ab4ff":'#f5d63b'):'#EAEAEA'}}   id='pause' disabled={!["Ready", "Paused", "Active"].includes(this.props.autoPilotState)}  onClick={() => {this.props.pauseResumeAutoPilot()}}>{this.props.autoPilotState === "Active" ? <FaPause/>:<FaPlay/>}</Button> 
                </OverlayTrigger>
                
                <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip(this.props,"Stop")}
                        >
                    <Button className="control-button" id='stop' style={{background:this.props.autoPilotState !== "Off" ?'#ff7a7a':'#EAEAEA'}}  disabled={this.props.autoPilotState === "Off"}  onClick={this.props.submitStop}><FaStop/>  </Button> 
                </OverlayTrigger>
                
                <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip(this.props,"Show dispatch routes")}
                        >
                <Button className="control-button" style={{background:'#7ab4ff'}} onClick={() => {this.props.showBatteryDispatches()}}><FaRoute/></Button> 
                </OverlayTrigger>
                <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip(this.props,"Show all routes")}
                        >
                <Button className="control-button" style={{background:this.props.autoPilotState !== "Off" ?'#7ab4ff':'#EAEAEA'}} disabled={!["Ready", "Paused", "Active"].includes(this.props.autoPilotState)} onClick={() => {this.props.showBatteryAll()}}><SiReactrouter/></Button>
                </OverlayTrigger>
                <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip(this.props,"Configuration sectors")}
                        >
                <Button className="control-button" onClick={()=>{this.props.openSectorConfigModal()}}><FcDataConfiguration/></Button> 
                </OverlayTrigger>
                
                <OptionButton></OptionButton>
               
                <ThemeTogglerButton></ThemeTogglerButton>
                </div>
            </div>
            );
    }

    
  }