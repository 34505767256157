import './info-panel-styles.css';
import React, { Component } from "react";
import { Link, Element } from "react-scroll";
//import StickyBox from "react-sticky-box";
import Card from "react-bootstrap/Card";
 
const categories = [
  { id: 1, name: "Services" },
  { id: 2, name: "Engineering" },
  { id: 3, name: "Services" },
  { id: 4, name: "Training" },
  { id: 5, name: "Support" },
  { id: 6, name: "Research and Development" },
  { id: 7, name: "Training" },
  { id: 8, name: "Human Resources" },
  { id: 9, name: "Services" },
  { id: 10, name: "Legal" },
  { id: 11, name: "Sales" },
  { id: 12, name: "Legal" },
  { id: 13, name: "Accounting" },
  { id: 14, name: "Business Development" },
  { id: 15, name: "Accounting" },
  { id: 16, name: "Services" },
  { id: 17, name: "Training" },
  { id: 18, name: "Research and Development" },
  { id: 19, name: "Human Resources" },
  { id: 20, name: "Legal" },
  { id: 21, name: "Research and Development" },
  { id: 22, name: "Human Resources" },
  { id: 23, name: "Services" },
  { id: 24, name: "Research and Development" },
  { id: 25, name: "Research and Development" },
  { id: 26, name: "Accounting" },
  { id: 27, name: "Product Management" },
  { id: 28, name: "Human Resources" },
  { id: 29, name: "Legal" },
  { id: 30, name: "Legal" },
  { id: 31, name: "Legal" },
  { id: 32, name: "Research and Development" },
  { id: 33, name: "Services" },
  { id: 34, name: "Research and Development" },
  { id: 35, name: "Research and Development" },
  { id: 36, name: "Accounting" },
  { id: 37, name: "Product Management" },
  { id: 38, name: "Human Resources" },
  { id: 39, name: "Legal" },
  { id: 40, name: "Legal" },
  { id: 41, name: "Research and Development" },
  { id: 42, name: "Accounting" },
  { id: 43, name: "Product Management" },
  { id: 44, name: "Human Resources" },
  { id: 45, name: "Legal" },
  { id: 46, name: "Legal" }
];

export default class HorizontalScroll extends Component {
    constructor(props) {
      super(props);
      this.props.boxList.forEach(category => {
        this[category.id] = React.createRef();
      });
    }
  
    render() {
      return (
        <Card>
            <ul
            style={{
              marginTop: "10px",
              display: "flex",
              flexDirection: "row",
              overflowY: "hidden",
              whiteSpace: "nowrap",
              listStyleType: "none",
              paddingLeft: "20px",
              backgroundColor: "#e2e2e2",
              flexWrap: "nowrap",
              height: "70px",
              justifyItems: "center"
            }}
          >
            {this.props.boxList.map(category => (
              <li
                key={category.id}
                style={{
                  display: "inline-block",
                  margin: "2px",
                  fontSize: "12px"
                }}
                ref={this[category.id]}
              >
                <Link
                  activeClass="activeCategoryLink"
                  className={category.id}
                  to={category.id.toString()}
                  spy={true}
                  smooth={true}
                  duration={500}
                  offset={-50}
                  onSetActive={() => ""}
                >
                  #{(category.id+1).toString()} {category.name}<br />
                  {category.summary}<br />
                  {category.location}
                </Link>
              </li>
            ))}
          </ul>
        </Card>
      );
    }
  }
  