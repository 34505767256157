import keplerGlReducer from 'kepler.gl/reducers';
import {createStore, combineReducers, applyMiddleware} from 'redux';
import {taskMiddleware} from 'react-palm/tasks';


function appReducer() {
    return {}
}

const reducer = combineReducers({
  keplerGl: keplerGlReducer.initialState({
    uiState: {
          readOnly: true,
          activeSidePanel: null,  
          currentModal: null      
    }
  }),
  app: appReducer
});

// create store
const store = createStore(reducer, {}, applyMiddleware(taskMiddleware));

export default store;