import  { Component } from 'react';
import {Row, Col} from 'react-bootstrap'; 

export class QueueItem extends Component {
    
  
    render() { 
        return (
          <div>
              <Row>
                <Col sm={1} >
                  {`${this.props.data.index}`}
                  </Col>
                  <Col sm={3}>
                  {`${this.props.data.name}`}
                  </Col>
              
                  <Col sm={6}>
                  {`${this.props.data.location + " " + this.props.data.sector}`}
                  </Col>
                  
                  <Col sm={2}>
                  {`${this.props.data.summary}`}
                  </Col>
              </Row>
          </div>
        );
    }
}
