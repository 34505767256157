import {DisplayModeContext} from './DisplayModeContext';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Button from 'react-bootstrap/Button';
import { MdNightlightRound,MdLightMode} from 'react-icons/md';

const renderTooltip = (props,message) => (
    <Tooltip id="button-tooltip" {...props}>
      {message}
    </Tooltip>
  );
function ThemeTogglerButton(props) {
    // Le Theme Toggler Button reçoit non seulement le thème
    // mais aussi une fonction toggleTheme du contexte
    return (
      <DisplayModeContext.Consumer>
        {({displayMode, toggleMode}) => (
            displayMode === 'light' ?
            <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip(props,"Night mode")}
                        >
                <Button className="control-button" onClick={toggleMode}><MdNightlightRound/></Button> 
            </OverlayTrigger>
             :
             <OverlayTrigger
             placement="right"
             delay={{ show: 250, hide: 400 }}
             overlay={renderTooltip(props,"Light mode")}
             >
                <Button className="control-button" onClick={toggleMode}><MdLightMode/></Button> 
            </OverlayTrigger>
        )}
      </DisplayModeContext.Consumer>
    );
  }
  
  export default ThemeTogglerButton;
  