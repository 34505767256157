import { Component, CSSProperties } from "react";
import { 
  ALL_OPTION,
  ALL_OPTIONS_VALUE,
    groupedOptions,
  }  from './Constant'
import Select from 'react-select'

const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  };
  const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
  };
  
export default class AreaSelectionComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {areas: this.initAreas(props.areas), options: [...groupedOptions]};
        
        console.log(this.state.areas)
      }
      initAreas(areas){
        if(areas.length >= 49){
          return ALL_OPTION

        }
        else{
          const rep = []
          
            for(var area of areas){
              const number = area.slice(-1);
              const editedText = area.slice(0, -1)
              rep.push({value: editedText+ '_'+ number , label: area});
            }
            return rep;
        }


      }
      changeOption(newElements){
        
        let copyGroupedOptions = [...groupedOptions]; 
        let result = [];
        for(const element of newElements){
            let index = 0;
            const elementCode  = element.value.slice(0,2).replace("_","")
            if(element.value === 'Area_All') {this.setState({areas: [element], options: []}); return [element]  ;}
            for(const option of copyGroupedOptions){
                const code  = option.label.slice(0,2).replace(" ","")
                if(element.value.includes("All") && elementCode === code) {
                    const copyElements = [...newElements];
                    result = copyElements.filter(element => !(element.value.slice(0,2).replace("_","") === code) || element.value.includes("All")  );
                    this.setState({areas: result})
                    copyGroupedOptions.splice(index,1);
                }
                index++;
            }
        }
         
        this.setState({options: copyGroupedOptions });
        return newElements;
      }
      handleAreaChange(newElements){
        this.setState({areas: newElements})
        const rep = this.changeOption(newElements);
        const answer = this.send(rep);
        this.props.update(answer);
      }
      send(array){
        let rep = [];
        const options = [...groupedOptions];
          for(const element of array){
            if(element.label === "All") return ALL_OPTIONS_VALUE;
            else if(element.value.includes("All")){
              for(const option of options ){
                if(option.label === element.label){
                  const filter = option.options.filter((x,index)=> index !== 0);
                  const map = filter.map(x => x.label);
                  rep = rep.concat(map);
                }
              }
            }
            else{
              rep.push(element.label);
            }
          }
        return rep
      }
render(){
    
    return (
        <Select value={this.state.areas} onChange={(newElement)=>{this.handleAreaChange(newElement)}} isMulti  options={this.state.options} />
    )
}
}