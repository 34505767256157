import moment from 'moment'

export function displayTime(time){
    var m = moment().utcOffset(0);
   
    m.set({hour:0,minute:0,second:0,millisecond:0});
    return m.add(time, 'seconds');
  }

  export function apiFormatWorkingTime(time){
    return  time.seconds();
  }