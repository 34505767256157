import  { Component } from 'react';
import {QueueItem} from './QueueItemComponent'
import { getBatterySwapResource, resumeAutoPilot} from  './Caller'
import {Button, Tab,Accordion, ListGroup, Row,Col, Nav} from 'react-bootstrap'
import {FaDotCircle, FaPauseCircle, FaPlayCircle, FaExclamationCircle, FaLock, FaLockOpen} from 'react-icons/fa'
import {pauseResource, resumeResource, selectResource, deselectResource, toggleLockResource} from './Caller.js'
import AreaSelectionComponent from './AreaSelectionComponent';
import { apiFormatWorkingTime, displayTime } from './helper/TimeHelper';
import './BottomMenuComponent.css';

const API_URL = process.env.REACT_APP_AUDIENCE;
const QUEUE_URL = API_URL + "dispatcher/queues_stream/";
const STATUS_URL = API_URL + "dispatcher/resource_states_stream/";

export class BottomMenu extends Component {
    constructor(props){
      super(props)
      this.state = {queues: [], status: [], resources:[]};
    }
    async componentDidMount(){
     // await this.initResourcesInfo();
     console.log("cddddddd")
     console.log(this.props.resources)
     this.initQueues();
     this.initStatus();
    }
    // async initResourcesInfo(){
    //   const res = await getBatterySwapResource();
    //   for(let resource of res.context){
    //     resource.working_time = this.displayTime(resource.working_time);
    //   }

    //   this.setState({infos: res.context});
    //   console.log("---------------------------------------------");
    //   console.log(res.context);
    // }
    initQueues ()   {
      var eventsource = new EventSource(QUEUE_URL);
      eventsource.onopen = function() {
      }
      eventsource.onerror = function(e) {
          console.log("Error: " + e);
      }
      eventsource.onmessage = (e) => {
          var data = JSON.parse(e.data);
          // set the state of the auto pilot attribute 'autoPilotState'
          // with the value of the attribute 'status' of the event
          console.log(data)
          this.setState({queues: data['Battery swap resources'] });
      }
    }
    
    initStatus ()   {
      var eventsource = new EventSource(STATUS_URL);
      eventsource.onopen = function() {
      }
      eventsource.onerror = function(e) {
          console.log("Error: " + e);
      }
      eventsource.onmessage = (e) => {
          var data = JSON.parse(e.data);
          // set the state of the auto pilot attribute 'autoPilotState'
          // with the value of the attribute 'status' of the event
          console.log('status: ')
          console.log(data)
          var allStatus = {}
          for (var name in data){
            allStatus[name] = data[name].status;
          }
          this.setState({status: allStatus });
          
          // resource setState
          var resourceData = []
          for(var name in data){
            resourceData.push(data[name])
          }
          this.props.onChangeResource(resourceData)
      }
    }

  getStatusIcon = (status, selected) => {
    if (selected === 0) {
      return <FaDotCircle color='grey'/>
    }
    switch(status) {
      case "active":
        return <FaDotCircle color='green'/>
      case "paused":
        return <FaPauseCircle color='orange'/>
      case "ready":
        return <FaExclamationCircle color='blue'/>
      default:
        return <FaDotCircle color='grey'/>
    }
  }

  getPauseResumeButtonIcon = (status) => {
    switch(status) {
      case "active":
        return <FaPauseCircle color='orange' size="20px"/>
      case "paused":
        return <FaPlayCircle color='green' size="20px"/>
      case "ready":
        return <FaPlayCircle color='blue' size="20px"/>
      default:
        return <FaDotCircle color='grey' size="20px"/>
    }
  }

  getLockIcon = (locked) => {
    if (locked) {
      return <FaLock color='orange' size="20px"/>
    }
    return <FaLockOpen color='4a90e2' size="20px"/>
  }


  getSelectButtonIcon = (status, selected) => {
    if (selected === 0) {
      return <FaDotCircle color='green' size="20px"/>
    }
    switch(status) {
      case "off":
        return <FaDotCircle color='green' size="20px"/>
      default:
        return <FaDotCircle color='red' size="20px"/>
    }
  }

  getSelectButtonText = (status, selected) => {
    if (selected == 0) {
      return "Select "
    }
    switch(status) {
      case "off":
        return "Select "
      default:
        return "Deselect "
    }
  }

  handlePauseResume = (resourceName) => {
    if (this.state.status[resourceName] === "active"){
      pauseResource(resourceName)
    }
    else if (this.state.status[resourceName] === "paused" || this.state.status[resourceName] === "ready" ){
      resumeResource(resourceName)
    }
  }
  handleLockBtn = (name)=> {
    if (name !== "") { 
      const data = { resource: name } 
      toggleLockResource(data);
    }
  }
  
  handleSelect = (resourceName, selected) => {
    if (this.state.status[resourceName] === "off" || selected === 0){
      selectResource(resourceName)
    }
    else {
      deselectResource(resourceName)
    }
  }


    render() { 
        return (
          this.props.resources.length === 0? <div className='flex-center'>No available resources </div>:<Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row>
                  <Col sm={3}>
                    <Nav variant="pills" defaultActiveKey={0} className="flex-column">
                    {Object.keys(this.state.status).map((element,index)=>{return(
                      <Nav.Item>
                        <Nav.Link eventKey={index}>{this.getStatusIcon(this.state.status[element], this.props.resources[index]!=null? this.props.resources[index].selected:"")} {this.props.resources[index]!=null? this.props.resources[index].display_name:""}</Nav.Link>
                      </Nav.Item>
                    )})}
                    </Nav>
                  </Col>
                  <Col  sm={9}>
                    <Tab.Content>
                    {Object.keys(this.state.queues).map((element, index) => {
                  return(
                    <Tab.Pane key={index} eventKey={index} title={index}>
                      <div id='info'>
                        <div className='flex-center'>{"Change Status:"}<button type="button" class="btn btn--outline-warning" onClick={() => {this.handlePauseResume(this.props.resources[index]!=null? this.props.resources[index].name:"")}}>{this.getPauseResumeButtonIcon(this.props.resources[index]!=null? this.state.status[this.props.resources[index].name]:"")}</button></div>
                        <div className='flex-center'>{'Final Inventory: '}{this.props.resources[index]!=null? this.props.resources[index].inventory:""}</div>
                        <div className='flex-center'>{'Time Left: '}{this.props.resources[index]!=null? this.props.resources[index].working_time.format("HH:mm"):""}</div>
                        <div>
                      Assigned Sectors:  
                        {this.props.resources[index]!=null && this.props.resources[index].neighbors.length !== 49? this.props.resources[index].neighbors.map((neighbor)=>{return this.props.resources[index].neighbors.length === 1 ? ` ${neighbor}`:` ${neighbor},`;} ): " All"}
                        <button type="button" class="btn btn--outline-warning" size='sm' onClick={() => {this.handleLockBtn(this.props.resources[index]!=null? this.props.resources[index].name:"")}}>{this.props.resources[index]!=null? this.getLockIcon(this.props.resources[index].is_sectors_locked):""}</button>
                      </div>
                      </div>
                      <div>Change Sectors: <AreaSelectionComponent areas={this.props.resources[index]!=null ? this.props.resources[index].neighbors:[]} update={ (x)=>{this.props.handleChangeArea(index,x)}}></AreaSelectionComponent> </div>

                    

                      <Accordion  alwaysOpen>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>Assigned queue</Accordion.Header>
                        <Accordion.Body>
                          <ListGroup >
                            {this.state.queues[element].assinged_queue.map((task)=>{
                              return(
                                <ListGroup.Item><QueueItem data={task}/></ListGroup.Item>
                                )
                              })}
                          </ListGroup>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>Dispatched queue</Accordion.Header>
                        <Accordion.Body>
                        <ListGroup >
                          {this.state.queues[element].dispatched_queue.map((task)=>{
                            return(
                              <ListGroup.Item><QueueItem  data={task}/></ListGroup.Item>
                              )
                            })}
                        </ListGroup>
                        
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    <div margin="5px">
                    <button type="button" className="select-button" class="btn btn-outline-primary" style={{marginTop: "10px"}} onClick={() => {this.handleSelect(element, this.props.resources[index]!=null? this.props.resources[index].selected:"")}}>{this.getSelectButtonText(this.state.status.hasOwnProperty(element)? this.state.status[element]:"", this.props.resources[index]!=null? this.props.resources[index].selected:"")}{this.getSelectButtonIcon(this.state.status.hasOwnProperty(element)? this.state.status[element]:"", this.props.resources[index]!=null? this.props.resources[index].selected:"")}</button>
                    </div>
                  </Tab.Pane>
                  )
                })
                
                }
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
        );
    }
}