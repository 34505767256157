import  { Component } from 'react';

import './info-panel-styles.css';
const API_URL = process.env.REACT_APP_AUDIENCE


const TRACE_URL = API_URL + "dispatcher/trace_stream/";


export class TraceConsole extends Component {
    constructor(props){
        super(props);
        this.state = { trace: ""};
    }
    componentDidMount(){
      this.initialize_trace_stream();
      //this.scrollToBottom();
    }
    componentDidUpdate(){
        //this.scrollToBottom();
    };
    initialize_trace_stream = () =>  {
        var eventsource = new EventSource(TRACE_URL);
        eventsource.onopen = function() {
        }
        eventsource.onerror = function(e) {
            console.log("Error: " + e);
        }
        eventsource.onmessage = (e) => {
            var data = JSON.parse(e.data);
            
            // set the state of the auto pilot attribute 'autoPilotState'
            // with the value of the attribute 'status' of the event
            this.setState({trace: data.trace });
        }
    }
    /*scrollToBottom = () => {
        this.messagesEnd.scrollIntoView({ behavior: "smooth" });
      }*/
    render() { 
        return (
            <div>
                <div className="display-linebreak" style={{fontSize: "12px"}}> 
                {(this.state.trace)} 
                </div>
                {/*<div style={{ float:"left", clear: "both" }}
                ref={(el) => { this.messagesEnd = el; }}>
                </div>*/}
            </div>
        );
    }
}
