export const ALL_OPTION= [
    { value: 'Area_All', label: 'All' }
];
export const C_Options = [
    {value: 'C_All', label: 'C Area'},
    {value: 'C_6', label: 'C6'},
    {value: 'C_8', label: 'C8'},
];
export const CV_Options = [
    {value: 'CV_All', label: 'CV Area'},
    {value: 'CV_1', label: 'CV1'},
    {value: 'CV_2', label: 'CV2'},
    {value: 'CV_3', label: 'CV3'},
    {value: 'CV_4', label: 'CV4'},
    {value: 'CV_5', label: 'CV5'}
];
export const E_Options = [
    {value: 'E_All', label: 'E Area'},
  {value: 'E_1', label: 'E1'},
  {value: 'E_2', label: 'E2'},
  {value: 'E_3', label: 'E3'},
];

export const N_Options = [
    {value: 'N_All', label: 'N Area'},
    {value: 'N_1', label: 'N1'},
    {value: 'N_2', label: 'N2'},
    {value: 'N_3', label: 'N3'},
    {value: 'N_4', label: 'N4'}
];
export const NO_Options = [
    {value: 'NO_All', label: 'NO Area'},
    {value: 'NO_1', label: 'NO1'},
    {value: 'NO_2', label: 'NO2'}
];
export const O_Options = [
    {value: 'O_All', label: 'O Area'},
    {value: 'O_1', label: 'O1'},
    {value: 'O_2', label: 'O2'},
    {value: 'O_3', label: 'O3'},
    {value: 'O_4', label: 'O4'},
    {value: 'O_5', label: 'O5'},
];
export const P_Options = [
    {value: 'P_All', label: 'P Area'},
    {value: 'P_1', label: 'P1'},
    {value: 'P_2', label: 'P2'},
    {value: 'P_3', label: 'P3'},
    {value: 'P_4', label: 'P4'},
    {value: 'P_5', label: 'P5'},
    {value: 'P_6', label: 'P6'},
    {value: 'P_7', label: 'P7'},
    {value: 'P_8', label: 'P8'}];


export const R_Options = [
    {value: 'R_All', label: 'R Area'},
    {value: 'R_1', label: 'R1'},
    {value: 'R_2', label: 'R2'},
    {value: 'R_3', label: 'R3'},
    {value: 'R_4', label: 'R4'},
    {value: 'R_5', label: 'R5'},
    {value: 'R_6', label: 'R6'},
    
  ];
export const S_Options = [
    {value: 'S_All', label: 'S Area'},
    {value: 'S_1', label: 'S1'}
];
export const SE_Options = [
    {value: 'SE_All', label: 'SE Area'},
    {value: 'SE_1', label: 'SE1'},
    {value: 'SE_2', label: 'SE2'},
    {value: 'SE_3', label: 'SE3'},
    {value: 'SE_4', label: 'SE4'}
];
export const SO_Options = [
    {value: 'SO_All', label: 'SO Area'},
    {value: 'SO_1', label: 'SO1'},
    {value: 'SO_2', label: 'SO2'},
    {value: 'SO_3', label: 'SO3'},
    {value: 'SO_4', label: 'SO4'},
    {value: 'SO_5', label: 'SO5'},
    {value: 'SO_6', label: 'SO6'},
    {value: 'SO_7', label: 'SO7'}
    ];

  export const VM_Options = [
    {value: 'VM_All', label: 'VM Area'},
    {value: 'VM_1', label: 'VM1'},
    {value: 'VM_2', label: 'VM2'},
  ];


export const groupedOptions = [
    {
        label: 'Area_All',
        options: ALL_OPTION,
    },
    {
        label: 'C Area',
        options: C_Options,
    },
    {
        label: 'CV Area',
        options: CV_Options,
    },
    {
        label: 'E Area',
        options: E_Options,
    },
    {
        label: 'N Area',
        options: N_Options,
    },
    {
        label: 'NO Area',
        options: NO_Options,
    },
    {
        label: 'O Area',
        options: O_Options,
    },
    {
        label: 'P Area',
        options: P_Options,
    },
    {
        label: 'R Area',
        options: R_Options,
    },
    {
        label: 'S Area',
        options: S_Options,
    },
    {
        label: 'SE Area',
        options: SE_Options,
    },
    {
        label: 'SO Area',
        options: SO_Options,
    },
    {
        label: 'VM Area',
        options: VM_Options,
    },
  ];
  export const ALL_OPTIONS_VALUE= [
    'C6',
    'C8',
    'CV1',
    'CV2',
    'CV3',
    'CV4',
    'CV5',
    'E1',
    'E2',
    'E3',
    'N1',
    'N2',
    'N3',
    'N4',
    'NO1',
    'NO2',
    'O1',
    'O2', 
    'O3',
    'O4',
    'O5',
    'P1',
    'P2',
    'P3',
    'P4',
    'P5',
    'P6',
    'P7',
    'P8',
    'R1',
    'R2',
    'R3',
    'R4',
     'R5', 
     'R6',
     'S1', 
     'SE1',
    'SE2',
    'SE3',
    'SE4', 
    'SO1',
       
    'SO2', 
       'SO3',
        'SO4',
'SO5',
'SO6',
 'SO7',
        'VM1',
        'VM2'

  ]