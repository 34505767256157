import React from 'react';
import logo1 from './img/truck.svg'

class TabNav extends React.Component {
  render() { //"nav nav-expand nav-pills nav-fill">   nav-item
    return (
        <div>
        <ul className= "nav "> 
            {
            this.props.tabs.map(tab => {
                        const active = (tab === this.props.selected ? 'active ' : '' );
                        let d_path = "M32 18l-4-8h-6v-4c0-1.1-0.9-2-2-2h-18c-1.1 0-2 0.9-2 2v16l2 2h2.536c-0.341 0.588-0.536 1.271-0.536 2 0 2.209 1.791 4 4 4s4-1.791 4-4c0-0.729-0.196-1.412-0.536-2h11.073c-0.341 0.588-0.537 1.271-0.537 2 0 2.209 1.791 4 4 4s4-1.791 4-4c0-0.729-0.196-1.412-0.537-2h2.537v-6zM22 18v-6h4.146l3 6h-7.146z";
                        if (tab === 'Demande'){ d_path = "M0 26h32v4h-32zM4 18h4v6h-4zM10 10h4v14h-4zM16 16h4v8h-4zM22 4h4v20h-4z";}
                        else if (tab === 'Relocation'){ d_path = "M9.914 11.086l-2.829 2.829 8.914 8.914 8.914-8.914-2.828-2.828-6.086 6.086z";}
                        else if (tab === 'Assignation'){ d_path = "M32 18l-4-8h-6v-4c0-1.1-0.9-2-2-2h-18c-1.1 0-2 0.9-2 2v16l2 2h2.536c-0.341 0.588-0.536 1.271-0.536 2 0 2.209 1.791 4 4 4s4-1.791 4-4c0-0.729-0.196-1.412-0.536-2h11.073c-0.341 0.588-0.537 1.271-0.537 2 0 2.209 1.791 4 4 4s4-1.791 4-4c0-0.729-0.196-1.412-0.537-2h2.537v-6zM22 18v-6h4.146l3 6h-7.146z";}
                        const color_class = tab === this.props.selected ?'filter-white':'filter-blue';        
                return (
                <li className="nav-item " key={ tab }>
                    <a className={"nav-link " + active} onClick={ () => this.props.setSelected(tab) }>
                    
                    
                    <svg class={color_class} version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32">
                    <path d={d_path}></path>
                    </svg>
                    
                    
                    </a>
                </li>
                );
            })
            }
        </ul>
        { this.props.children }
        </div>
    );
  }
}
export default TabNav; 