import {Modal, Form, Col, Row, Button, OverlayTrigger, Tooltip} from 'react-bootstrap';
import { sendBatterySwapConfig } from './Caller.js';
import { Component } from "react";
import { DisplayModeContext } from './DisplayModeContext.js';

export default class OptionsModal extends Component{
    constructor(props){
        super(props);
        this.state = { context: props.config};
        console.log("-----------------------------------");
        console.log(props.config)
        this.handelChange = this.handelChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }
    handelChange(event,index){
        const copyContext = [...this.state.context];
        copyContext[index].value = event.target.value;
        this.setState({context: copyContext});
    }
    async handleSubmit(){
        await sendBatterySwapConfig(this.state);
        this.props.close();
    }
    handleCancel(){
        this.props.close();
    }
    static contextType = DisplayModeContext;
    render(){
        return(
            
            <Modal id={this.context.displayMode} backdrop="false" show={this.props.show} centered>
                
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Config Battery Swap
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        {this.state.context.map((element, index) =>{
                            return(
                                <Form.Group key={index}as={Row} className="mb-3" controlId="formHorizontalEmail">
                                    <Form.Label column sm={4}>
                                    
                                    {element.name}
                                    </Form.Label>
                                    <Col sm={6}>
                                    <Form.Control name={element.name} onChange={(e) => this.handelChange(e, index)} value={element.value}/>
                                    </Col>
                                    <Col sm={1}>
                                    <OverlayTrigger
                                        overlay={
                                            <Tooltip >
                                            {element.description}.
                                            </Tooltip>
                                        }
                                        >
                                        
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle-fill" viewBox="0 0 16 16">
                                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"></path>
                                        </svg>
                                        </OverlayTrigger>
                                    </Col>
                                    
                                </Form.Group>
                            )
                        })}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.handleCancel} variant="secondary">Cancel</Button>
                    <Button onClick={this.handleSubmit}>Submit</Button>
                </Modal.Footer>
            </Modal>
        )
    }

}