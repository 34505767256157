import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import { Component } from "react";
import {sendBatterySwapResource, startAutoPilot,getBatterySwapConfig,getBatterySwapResource,getStations} from './Caller.js'
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import OptionsModal from './OptionsModalComponent.js';
import AreaSelectionComponent from './AreaSelectionComponent.js';
import { DisplayModeContext } from './DisplayModeContext.js';
const ALL_OPTION= { value: 'All', label: 'All' }
export default class ResourceModalComponent extends Component {
    constructor(props) {
      super(props);
      this.state = {optionMenu: false, config: [] ,enable: true, context: [], hide: false, show: true,isLoading: true};
      this.stations = new Set();
      this.errors = new Map();
      this.options = [
        ALL_OPTION
      ]
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleClickOption = this.handleClickOption.bind(this);
      this.closeOptions = this.closeOptions.bind(this);
      this.handleAreaChange = this.handleAreaChange.bind(this);
    }
    async componentDidMount(){
      const repResource = await getBatterySwapResource();
        console.log(repResource);
        const repStations = await getStations();
        this.stations = new Set(repStations.stations);
        this.setState( {context: repResource.context});
        this.setState({isLoading: false})
    }
    closeOptions(){
      this.setState({optionMenu: false});
    }
    findElement(event, array){
      for(const element of array){
        if(event.target.id === element.name){
          return element;
        }
      }
    }
    findElementFromElement(elementToFind, array){
      for(const element of array){
        if(elementToFind.name === element.name){
          return element;
        }
      }
    }
    validateButton(valid, target){
      if(valid) {
        this.errors.delete(target);
        target.classList.remove('is-invalid');
      }
      else{
        this.setState({enable: false});
        this.errors.set(target, 'inventory');
        target.classList.add('is-invalid');
      }
      if(this.errors.size === 0) this.setState({enable: true});;
    }


    handleChange(event) {
      //TODO FUSIONNERS START ET INVENTORY
      const copyContext = [...this.state.context];
      let element = this.findElement(event, copyContext);
      if (event.target.name === 'selected') this.handleSelectedChange(element);
      else if (event.target.name === 'working_time') this.handleTime(event,element);
      else if (event.target.name === 'is_taking_pause') this.handlePause(event,element);
      else  {
        let isValid = false;
        if(event.target.name === 'start_station') isValid = this.handleAddrChange(event,element);
        else if(event.target.name === 'inventory') isValid  = this.handleInvChange(event,element);
        this.validateButton(isValid, event.target);
      }

      this.setState({context: copyContext});
    }
    handleAreaChange = (newValue,index) => {
      console.log("pog");
      const copyContext = [...this.state.context];
      copyContext[index].neighbors = newValue;
      this.setState({context: copyContext});
      console.log(newValue);
    };
    handlePause(event,element){
      const copyContext = [...this.state.context];
      let buffer = moment().utcOffset(0);
      buffer.set({hour:0,minute:15,second:0,millisecond:0})
      element = this.findElementFromElement(element,copyContext);
      if(element.is_taking_pause){
        element.working_time = this.convert(this.displayTime(element.working_time).add(buffer));
      }
      else{
        element.working_time = this.displayTime(element.working_time).diff(buffer,'seconds');
      }
      element.is_taking_pause = !element.is_taking_pause;
      this.setState(copyContext);
    }
    handleInvChange(event,element) {
      element.inventory = event.target.value;
      const parse = parseInt(event.target.value)
      return !isNaN(parse) && (0 <= parse && parse < 50);
      
    }
    convert(value){
      let answer  = parseInt(value.minutes())*60;
      answer  += parseInt(value.hours())*3600;
      return answer
    }
    handleTime(value,element){
      const copyContext = [...this.state.context];
      element = this.findElementFromElement(element,copyContext);
      element.working_time = this.convert(value);
      this.setState(copyContext);
    }
    handleAddrChange(event,element) {
      element.start_station = event.target.value ;
      return this.stations.has(event.target.value)
    }
    handleSelectedChange(element) {
      element.selected = !element.selected ;
    }
    async handleClickOption() {
      const rep = await getBatterySwapConfig();
      this.setState({
        config: rep.context,
        optionMenu: true
      })
    }
    
    async handleSubmit(event) {
      //TODO prevenir si il y a une erreur
      const status = await sendBatterySwapResource({context: this.state.context});
      if (status === 204){
        this.props.close();
        startAutoPilot(()=>{});
      }
      event.preventDefault();
    }
    displayTime(time){
      var m = moment().utcOffset(0);
      m.set({hour:0,minute:0,second:0,millisecond:0})
      return m.add(time, 'seconds')
    }
    static contextType = DisplayModeContext;
    render() {
      return (
        <Modal id ={this.context.displayMode} backdrop="false" size="lg" show = {this.state.show}  aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
               Starting Battery Swap Menu
            </Modal.Title>
          </Modal.Header>
          {this.state.isLoading?
          <div className='flex-center spinner'>
          <Spinner size="xl"  animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
          :
           <form >
           <table class="table">
             <thead>
               <tr>
                 <th scope="col">Resource</th>
                 <th scope="col">Start station</th>
                 <th scope="col">Inventory</th>
                 <th scope="col">working time</th>
                 <th scope="col">Area</th>
                 <th scope="col">Pause</th>
                 <th scope="col">Selected</th>
                </tr>
             </thead>
             <tbody>
               {this.state.context.map((element,index) => {
                 return (
                 <tr key={index}> 
                 <th scope="row">{element.display_name}</th>
                 <td><input  id={element.name}  class="form-control " name="start_station" value={element.start_station} onChange={this.handleChange} type="text" placeholder="Default input" aria-label="default input example"/>
                 <div class="invalid-feedback">
                 enter a valid station
               </div></td>
                 <td><input  id={element.name}  class="form-control" name="inventory" value={element.inventory} onChange={this.handleChange} type="text" placeholder="Default input" aria-label="default input example"/></td>
                 <td><TimePicker  id={element.name}  class="form-control" name="working_time" value={this.displayTime(element.working_time)} onChange={e => this.handleTime(e,element)} allowEmpty={false} showSecond={false} minuteStep={15} /></td>
                 <td id ="area-column"><AreaSelectionComponent update={(newElement)=>{this.handleAreaChange(newElement,index)}} areas={element.neighbors} /></td>
                 <td>
                   <div class="form-check form-switch">
                     <input id={element.name}  checked={element.is_taking_pause} name="is_taking_pause" onChange={this.handleChange} type="checkbox" role="switch"  />
                   </div>
                 </td>
                 <td>
                   <div class="form-check form-switch">
                     <input id={element.name}  checked={element.selected} name="selected" onChange={this.handleChange} type="checkbox" role="switch"  />
                   </div>
                 </td>
               </tr>)
           })
          }
         </tbody>
       </table>
     </form>
          
          }
         
      {this.props.autoPilotState!="Off" && <p style={{color:"red"}}>   *Stop the auto-pilot before pressing 'Start', nothing will happen otherwise. </p>}
        {this.state.optionMenu && <OptionsModal show={this.state.optionMenu} close={this.closeOptions}  config={this.state.config}></OptionsModal> }
        {this.state.isLoading?<div></div>:<Modal.Footer>
          <button onClick={this.props.close}   class="btn btn-danger">Cancel</button>
          <button onClick={this.handleClickOption}  class="btn btn-secondary">Options</button>
          <button disabled={!this.state.enable} onClick={this.handleSubmit} class="btn btn-primary" type="submit">Start</button>
        </Modal.Footer>}
      </Modal>
      );
    }
  }