import React from 'react';

const Footer = () => (
	<footer>
		<p>
			Version <b>DEMO</b> v0.1
		</p>
	</footer>
);

export default Footer;