import getRouteConfig from "./routeConfig.js"

export default function getBatterySwapConfig(routesData) {
    console.log("in getBatterySwapConfig");
    console.log(routesData);
    return {
  "version": "v1",
  "config": {
    "visState": {
      "filters": [],
      "layers": routesData.map( (routeData) => { return getRouteConfig(routeData); }).concat([
        {
            "id": "nn7o1nj",
            "type": "point",
            "config": {
              "dataId": "batterySwapTask",
              "label": "Point",
              "color": [
                248,
                149,
                112
              ],
              "highlightColor": [
                252,
                242,
                26,
                255
              ],
              "columns": {
                "lat": "lat",
                "lng": "lon",
                "altitude": null
              },
              "isVisible": true,
              "visConfig": {
                "radius": 17.5,
                "fixedRadius": false,
                "opacity": 0.8,
                "outline": false,
                "thickness": 3.9,
                "strokeColor": null,
                "colorRange": {
                  "name": "Global Warming",
                  "type": "sequential",
                  "category": "Uber",
                  "colors": [
                    "#5A1846",
                    "#900C3F",
                    "#C70039",
                    "#E3611C",
                    "#F1920E",
                    "#FFC300"
                  ]
                },
                "strokeColorRange": {
                  "name": "Global Warming",
                  "type": "sequential",
                  "category": "Uber",
                  "colors": [
                    "#5A1846",
                    "#900C3F",
                    "#C70039",
                    "#E3611C",
                    "#F1920E",
                    "#FFC300"
                  ]
                },
                "radiusRange": [
                  17.5,
                  50
                ],
                "filled": true
              },
              "hidden": false,
              "textLabel": [
                {
                  "field": {
                    "name": "battery to swap",
                    "type": "integer"
                  },
                  "color": [
                    38,
                    20,
                    116
                  ],
                  "size": 18,
                  "offset": [
                    0,
                    0
                  ],
                  "anchor": "start",
                  "alignment": "center"
                },
                {
                  "field": {
                    "name": "last created average",
                    "type": "integer"
                  },
                  "color": [
                    105,
                    79,
                    214
                  ],
                  "size": 18,
                  "offset": [
                    0,
                    0
                  ],
                  "anchor": "start",
                  "alignment": "bottom"
                }
              ]
            },
            "visualChannels": {
              "colorField": {
                "name": "battery to swap",
                "type": "integer"
              },
              "colorScale": "quantize",
              "strokeColorField": null,
              "strokeColorScale": "quantile",
              "sizeField": {
                "name": "battery to swap",
                "type": "integer"
              },
              "sizeScale": "sqrt"
            }
          }
      ]),
      "interactionConfig": {
        "tooltip": {
          "fieldsToShow": {
            "batterySwapTask": [
                {
                  "name": "short name",
                  "format": null
                },
                {
                  "name": "sector",
                  "format": null
                },
                {
                  "name": "battery to swap",
                  "format": null
                },
                {
                  "name": "last created average",
                  "format": null
                }
              ]
          },
          "compareMode": false,
          "compareType": "absolute",
          "enabled": true
        },
        "brush": {
          "size": 0.5,
          "enabled": false
        },
        "geocoder": {
          "enabled": true
        },
        "coordinate": {
          "enabled": false
        }
      },
      "layerBlending": "normal",
      "splitMaps": [],
      "animationConfig": {
        "currentTime": null,
        "speed": 1
      }
    },
    "mapState": {
      "bearing": 0,
      "dragRotate": false,
      "latitude": 45.533880078635214,
      "longitude": -73.63798140808225,
      "pitch": 0,
      "zoom": 12.193531986995644,
      "isSplit": false
    },
    "mapStyle": {
      "styleType": "dark",
      "topLayerGroups": {},
      "visibleLayerGroups": {
        "label": true,
        "road": true,
        "border": false,
        "building": true,
        "water": true,
        "land": true,
        "3d building": false
      },
      "threeDBuildingColor": [
        218.82023004728686,
        223.47597962276103,
        223.47597962276103
      ],
      "mapStyles": {}
    }
  }
}
}