const http = require('http')
const request = require('request');
const HOSTNAME = process.env.REACT_APP_HOST_NAME;
const PORT = process.env.REACT_APP_AUDIENCE_PORT;
const API_URL = process.env.REACT_APP_AUDIENCE

const ROUTE_ENGINE_HOSTNAME = 'ec2-18-222-67-38.us-east-2.compute.amazonaws.com'
const ROUTE_ENGINE_PORT = '5000'

export function getNetworkStatus(callback) {
    const options = {
        hostname: HOSTNAME,
        port: PORT,
        path: '/dispatcher/network_state/',
        method: 'GET'
    }
    const request = http.request(options, (res) => {
        console.log(`STATUS: ${res.statusCode}`);

        res.on('data', (data) => {
            callback(data);
        })
    })

    request.on('error', (e) => {
        console.error(`problem with request: ${e.message}`);
    })

    request.end();
}

export function getRelocation(callback) {
    const options = {
        hostname: HOSTNAME,
        port: PORT,
        path: '/dispatcher/relocation/',
        method: 'GET'
    }
    const request = http.request(options, (res) => {
        console.log(`STATUS: ${res.statusCode}`);

        res.on('data', (data) => {
            callback(data);
        })
    })

    request.on('error', (e) => {
        console.error(`problem with request: ${e.message}`);
    })

    request.end();
}

export function getOperations(callback) {
    const options = {
        hostname: HOSTNAME,
        port: PORT,
        path: '/dispatcher/operations/',
        method: 'GET'
    }
    const request = http.request(options, (res) => {
        console.log(`STATUS: ${res.statusCode}`);

        res.on('data', (data) => {
            callback(data);
        })
    })

    request.on('error', (e) => {
        console.error(`problem with request: ${e.message}`);
    })
    
    request.end();
}

// Start auto-pilot get request
export function startAutoPilot(callback) {
    const options = {
        hostname: HOSTNAME,
        port: PORT,
        path: '/dispatcher/start/',
        method: 'GET'
    }
    const request = http.request(options, (res) => {
        console.log(`STATUS: ${res.statusCode}`);
        res.on('data', (data) => {
            callback(data);
        })
    })
    request.on('error', (e) => {
        console.error(`problem with request: ${e.message}`);
    })
    request.end();
}

export function pauseResource(resourceName) {
    const options = {
        hostname: HOSTNAME,
        port: PORT,
        path: '/dispatcher/pause_resource/?resource_name=' + resourceName,
        method: 'GET'
    }
    const request = http.request(options, (res) => {
        console.log(`STATUS: ${res.statusCode}`);
    })
    request.on('error', (e) => {
        console.error(`problem with request: ${e.message}`);
    })
    request.end();
}

export function resumeResource(resourceName) {
    const options = {
        hostname: HOSTNAME,
        port: PORT,
        path: '/dispatcher/resume_resource/?resource_name=' + resourceName,
        method: 'GET'
    }
    const request = http.request(options, (res) => {
        console.log(`STATUS: ${res.statusCode}`);
    })
    request.on('error', (e) => {
        console.error(`problem with request: ${e.message}`);
    })
    request.end();
}

export function selectResource(resourceName) {
    const options = {
        hostname: HOSTNAME,
        port: PORT,
        path: '/dispatcher/select_resource/?resource_name=' + resourceName,
        method: 'GET'
    }
    const request = http.request(options, (res) => {
        console.log(`STATUS: ${res.statusCode}`);
    })
    request.on('error', (e) => {
        console.error(`problem with request: ${e.message}`);
    })
    request.end();
}

export function deselectResource(resourceName) {
    const options = {
        hostname: HOSTNAME,
        port: PORT,
        path: '/dispatcher/deselect_resource/?resource_name=' + resourceName,
        method: 'GET'
    }
    const request = http.request(options, (res) => {
        console.log(`STATUS: ${res.statusCode}`);
    })
    request.on('error', (e) => {
        console.error(`problem with request: ${e.message}`);
    })
    request.end();
}
export async function toggleLockResource(data){
    const response = await fetch(`${API_URL}/dispatcher/lock_sectors/`,{method : "POST", mode:'cors',body: JSON.stringify(data)});
    return response.ok
}

export async function getBatteryAllRoutes() {
    const response = await fetch(`${API_URL}dispatcher/battery_all/`,{method : "GET", mode:'cors',});
    if(response.ok) return response.json();
}

export async function getBatteryDispatchRoutes() {
    const response = await fetch(`${API_URL}dispatcher/battery_dispatch/`,{method : "GET", mode:'cors',});
    if(response.ok) return response.json();
}

export async function getBatterySwap() {
    const response = await fetch(`${API_URL}dispatcher/battery_routing/`,{method : "GET", mode:'cors',});
    if(response.ok) return response.json();
}

export async function getBatterySwapConfig() {
    const response = await fetch(`${API_URL}dispatcher/battery_swap_config/`,{method : "GET", mode:'cors',});
    if(response.ok) return response.json();
}

export async function getBatterySwapResource() {
    const response = await fetch(`${API_URL}dispatcher/battery_swap_resource/`,{method : "GET", mode:'cors',});
    return response.json();
}
export async function getStations() {
    const response = await fetch(`${API_URL}data_etl/stations/`,{method : "GET", mode:'cors',});
    return response.json();
}
export async function sendBatterySwapResource(data) {
    const response = await fetch(`${API_URL}dispatcher/battery_swap_resource/`,{method : "PUT", mode:'cors',body: JSON.stringify(data)});
    return response.status;
}
export async function sendBatterySwapConfig(data) {
    const response = await fetch(`${API_URL}dispatcher/battery_swap_config/`,{method : "PUT", mode:'cors',body: JSON.stringify(data)});
    return response.status;
}
export async function sendRestart() {
    const response = await fetch(`${API_URL}dispatcher/restart/`,{method : "GET", mode:'cors'});
    return response.status;
}
export async function getConfig(){
    const response = await fetch(`${API_URL}dispatcher/configuration_sector_allocation/`,{method : "GET", mode:'cors',});
    if(response.ok) return response.json();
}   
export async function postConfig(data){
    const response = await fetch(`${API_URL}dispatcher/configuration_sector_allocation/`,{method : "POST", mode:'cors',body: JSON.stringify({content: data})});
    return response.status;
}
// Pause auto-pilot get request
export function pauseAutoPilot(callback) {
    const options = {
        hostname: HOSTNAME,
        port: PORT,
        path: '/dispatcher/pause/',
        method: 'GET'
    }
    const request = http.request(options, (res) => {
        console.log(`STATUS: ${res.statusCode}`);
        res.on('data', (data) => {
            callback(data);
        })
    })
    request.on('error', (e) => {
        console.error(`problem with request: ${e.message}`);
    })
    request.end();
}


// Resume auto-pilot get request
export function resumeAutoPilot(callback) {
    const options = {
        hostname: HOSTNAME,
        port: PORT,
        path: '/dispatcher/resume/',
        method: 'GET'
    }
    const request = http.request(options, (res) => {
        console.log(`STATUS: ${res.statusCode}`);
        res.on('data', (data) => {
            callback(data);
        })
    })
    request.on('error', (e) => {
        console.error(`problem with request: ${e.message}`);
    })
    request.end();
}

// Stop auto-pilot get request
export function stopAutoPilot(callback) {
    const options = {
        hostname: HOSTNAME,
        port: PORT,
        path: '/dispatcher/stop/',
        method: 'GET'
    }
    const request = http.request(options, (res) => {
        console.log(`STATUS: ${res.statusCode}`);
        res.on('data', (data) => {
            callback(data);
        })
    })
    request.on('error', (e) => {
        console.error(`problem with request: ${e.message}`);
    })
    request.end();
}

// POST request to ROUTE_HOSTNAME that takes
// a route and a callback function
export function getRoute(route, callback) {
    var post_data = {
        'route': route
    };
    
    const options = {
        hostname: ROUTE_ENGINE_HOSTNAME,
        port: ROUTE_ENGINE_PORT,
        path: '/generate_route',
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const request = http.request(options, (res) => {
        console.log(`STATUS: ${res.statusCode}`);
        
        res.on('data', (data) => {
            console.log(data)
            callback(data);
        })
    })

    request.on('error', (e) => {
        console.error(`problem with request: ${e.message}`);
    })
    console.log(post_data);
    console.log(JSON.stringify(post_data))
    request.write(JSON.stringify(post_data));
    request.end();
}

export function verificateCredentialGET(server, key, callback) { 
    const options = {
        hostname: HOSTNAME,
        port: PORT,
        path: '/dispatcher/login/?server=' + server + '&password=' + key,
        method: 'GET'
    }
    const request = http.request(options, (res) => {
        console.log(`STATUS: ${res.statusCode}`);
        res.on('data', (data) => {
            callback(data);
        })
    })
    request.on('error', (e) => {
        console.error(`problem with request: ${e.message}`);
    })
    request.end();
}
